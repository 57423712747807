.container_share {
    margin: 0;
    display: flex;
    align-items: flex-start;
    gap: 24px;
    flex-direction: column;

}

.title {
    font-family: "Barmeno", sans-serif !important;
    font-size: 24px;
    font-weight: 700;
    line-height: 28.8px;    
    color: rgba(49, 49, 49, 1);
}

.label {
    font-family: "Barmeno", sans-serif !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 16px !important;
    color: rgba(116, 116, 116, 1) !important;
    margin: 0 0 8px 0;
}

.textInput .textField {
    outline: 1px solid rgb(191, 191, 191) !important;
    border-radius: 8px !important;
    padding: 10px 8px !important;
    height: 56px;
}

.textInput .textField input  {
font-family: "Barmeno", sans-serif !important;
font-size: 16px !important;
font-weight: 400 !important;
line-height: 16px !important;
padding: 4px 0px;
}

.textInput .textField:hover {
border-color: rgb(191, 191, 191) !important;
}

.btn_copy {
    width: 120px;
    height: 56px;
    padding: 16px 0px 0px 0px;
    gap: 8px;
    border-radius: 8px 0px 0px 0px;
    background: rgba(225, 27, 34, 1) !important;
    font-family: "Barmeno", sans-serif !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 16px;
    color: rgba(255, 255, 255, 1) !important;
    align-self: end;
}

.share_content {
    font-family: "Barmeno", sans-serif !important;
    font-size: 20px !important;
    font-weight: 700 !important;
    line-height: 24px !important;
}

.social_icons {
    display: flex;
    gap: 25px;
}

.btn {
    width: 56px;
    height: 56px;
    border: 1px solid transparent;
    border-radius: 8px;
    box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn img {
    height: 24px;
    width: 24px;
}