@media screen and (min-width:1280px) {

    .faq-main-component {
        font-family: 'Barmeno', sans-serif;
        width: 100%;
    }

    .categories_container {
        background-image: url("../assets/chicken_leg1.png");
        background-size: cover;
        background-position: center;
        margin-top: -4%;
        max-width: 2000px !important;
        display: flex !important;
        flex-direction: column;
        align-items: center;
    }

    .c-background-image {
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
    }

    .individual_faq {
        background-color: white;
        max-width: 2000px !important;
        display: flex !important;
        flex-direction: column;
        align-items: center;
    }

    .faqNav {
        display: flex;
        align-items: center;
        font-size: 14px;
        margin-top: 20px;
        width: 100%;
    }

    .faqCategory {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 11px;
    }

    .textFiled {
        margin-top: 100;
        border-radius: 8px;
        border: 1px solid #CBD5E1;
        background: #FFF;
        box-shadow:
            0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03);
    }

    .MuiOutlinedInput-adornedStart {
        font-family: Barmeno, sans-serif !important;
    }

    .wingo-link {
        margin: 0px !important;
        padding: 8px 6px !important;
        font-family: Barmeno, Sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        text-transform: none;
        color: #747474 !important;
    }

    .search .MuiFormControl-fullWidth {
        background: white;
        margin-top: 87px;
        border-radius: 8px;
        height: 56px !important;
        font-family: Barmeno, sans-serif !important;
        max-width: 685px;
    }

    .gridContainer {
        padding: 0px 40px !important;
        margin: 80px 0 !important;
        display: grid !important;
        justify-items: center !important;
        grid-template-columns: repeat(auto-fill, minmax(calc((80% - 40px) / 3), 1fr));
        gap: 110px;
    }

    .cardContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-width: 380px;
        min-width: 380px;
        height: 329px;
        border-radius: 24px;
        border: 1px solid #4B4B4B;
        box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.20);
        backdrop-filter: blur(25px);
        background-image: url('../assets/card_bg.png');
        background-position: 50% 50%;
        background-size: cover;
        background-repeat: no-repeat;
        background:
            linear-gradient(119deg, rgba(255, 255, 255, 0.40) -18.04%, rgba(255, 255, 255, 0.10) 116.16%);
        text-align: center;
        padding: 30;
        box-sizing: border-box;
        cursor: pointer;
    }

    .cardContainer:active {
        opacity: 0.8;
    }

    .cardTitle {
        color: #FFF;
        text-align: center;
        font-family: Barmeno;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: -0.12px;
        margin: 24px 0px 0px 0px !important;

    }

    .cardDescription {
        color: #FFF;
        text-align: center;
        font-family: Barmeno;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        margin: 3px 0px 0px 0px;
    }

    .contactusContainer {
        margin: 0px !important;
        height: 390px;
    }

    .faqWrapper {
        margin-top: 82px;
        display: flex;
        flex-direction: column;
        gap: 69px;
        width: 100%;
    }

    .contactus {
        width: 100%;
        display: grid;
        justify-items: center;
        background-image: url("../assets/contact_us_bg.png");
        background-size: cover;
        background-position: center;
        height: 350px;
        margin-top: 40px;
        align-content: center;
    }

    .contactTitle {
        margin: 0px;
    }

    .contactDescription {
        margin: 11px 0 42px 0;
    }

    .MuiButtonBase-root.MuiButton-root.MuiButton-text.contct_us_button {
        border-radius: 8px !important;
        padding: 16px 0px !important;
        font-size: 16px !important;
        font-weight: 700 !important;
        width: 250px !important;
        height: 54px;
        color: #FFFFFF !important;
        background-color: #E11B23 !important;
    }

    .faq-contact-main-component {
        font-family: 'Barmeno', sans-serif;
    }

    .contactusFormContainer {
        background-color: white;
        width: 41.7%;
        padding-inline: 24px;
        margin-bottom: 108px;
        border-radius: 8px;
    }

    .contactus_field__wrapper .react-tel-input {
        font-family: 'Barmeno', sans-serif !important;
        font-size: 16px !important;
        position: relative !important;
        width: 100% !important;
        margin-top: 0px !important;
    }

    .contact_form-field,
    .contact_form-field:focus {
        height: 56px;
        width: 100%;
        border-radius: 8px;
        border: 1px solid black;
        padding: 0 10px;
        outline: none;
        background-color: white;
        font-weight: 500;
        box-sizing: border-box;
    }

    .contactus_field__wrapper .form-control,
    .field__wrapper .form-control:focus {
        height: 56px !important;
        width: 100% !important;
        border-radius: 8px !important;
        outline: none;
        border: 1px solid #313131;
    }

    .contactus_field__wrapper label {
        color: #475569;
    }

    .word-count {
        text-align: right;
        font-size: 12px !important;
        font-style: normal !important;
        font-weight: 500 !important;
        margin: 0px !important;
    }

    .contactus_field__wrapper .errors {
        position: relative;
        font-size: 12px;
        color: #E42328;
        font-weight: 500;
        letter-spacing: 1px;
        margin: 0px;
    }

    .error-input,
    .error-input:focus {
        height: 56px;
        width: 100%;
        border: 1px solid #E42328;
        border-radius: 8px;
        padding: 0 10px;
        outline: none;
        font-weight: 500;
        box-sizing: border-box;
        margin: 0px !important;
    }

    .popup_customform_faq .MuiDialog-paperWidthSm {
        max-width: 600px;
        width: 100%;
        min-height: auto;
        max-height: 600px !important;
    }


    .submitButtonDisabledCon {
        border-radius: 8px !important;
        padding: 16px 0px !important;
        font-size: 16px !important;
        font-weight: 700 !important;
        width: 100% !important;
        height: 54px;
        color: #909090 !important;
        background-color: #BFBFBF !important;
    }

    .submitButtonCon {
        border-radius: 8px !important;
        padding: 16px 0px !important;
        font-size: 16px !important;
        font-weight: 700 !important;
        width: 100% !important;
        height: 54px;
        color: #FFFFFF !important;
        background-color: #E11B23 !important;
    }
}

@media screen and (min-width:300px) and (max-width: 1279px) {

    .faq-main-component {
        font-family: 'Barmeno', sans-serif;
        width: 100%;
    }

    .categories_container {
        background-image: url("../assets/chicken_leg1.png");
        background-size: cover;
        background-position: center;
        margin-top: -4%;
        max-width: 2000px !important;
        display: flex !important;
        flex-direction: column;
        align-items: center;
    }

    .c-background-image {
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
    }

    .individual_faq {
        background-color: white;
        max-width: 2000px !important;
        display: flex !important;
        flex-direction: column;
        align-items: center;
    }

    .faqNav {
        display: flex;
        align-items: center;
        font-size: 14px;
        margin-top: 20px;
        width: 100%;
    }

    .faqCategory {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 11px;
    }

    .faqWrapper {
        margin-top: 82px;
        display: flex;
        flex-direction: column;
        gap: 69px;
        width: 100%;
    }

    .textFiled {
        margin-top: 100;
        border-radius: 8px;
        border: 1px solid #CBD5E1;
        background: #FFF !important;
        box-shadow:
            0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03);
    }

    .MuiOutlinedInput-adornedStart {
        font-family: Barmeno, sans-serif !important;
    }

    .search .MuiFormControl-fullWidth {
        background: white;
        margin-top: 87px;
        border-radius: 8px;
        height: 56px !important;
        font-family: Barmeno, sans-serif !important;
        max-width: 685px
    }

    .gridContainer {
        padding: 0px 40px !important;
        margin: 80px 0 !important;
        display: grid !important;
        justify-items: center !important;
        grid-template-columns: repeat(auto-fill, minmax(calc((91% - 40px) / 2), 1fr));
        gap: 110px;
    }

    .cardContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-width: 380px;
        min-width: 380px;
        height: 329px;
        border-radius: 24px;
        border: 1px solid #4B4B4B;
        box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.20);
        backdrop-filter: blur(25px);
        background-image: url('../assets/card_bg.png');
        background-position: 50% 50%;
        background-size: cover;
        background-repeat: no-repeat;
        background:
            linear-gradient(119deg, rgba(255, 255, 255, 0.40) -18.04%, rgba(255, 255, 255, 0.10) 116.16%);
        text-align: center;
        padding: 30;
        box-sizing: border-box;
        cursor: pointer;
    }

    .cardContainer:active {
        opacity: 0.8;
    }

    .cardTitle {
        color: #FFF;
        text-align: center;
        font-family: Barmeno;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: -0.12px;
        margin: 24px 0px 0px 0px !important;

    }

    .cardDescription {
        color: #FFF;
        text-align: center;
        font-family: Barmeno;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        margin: 3px 0px 0px 0px;
    }

    .contactus {
        width: 100%;
        display: grid;
        justify-items: center;
        background-image: url("../assets/contact_us_bg.png");
        background-size: cover;
        background-position: center;
        height: 350px;
        margin-top: 40px;
        align-content: center;
    }

    .contactusContainer {
        margin: 0px !important;
        height: 390px;
    }

    .contactTitle {
        margin: 0px;
    }

    .contactDescription {
        margin: 11px 0 42px 0;
    }

    .MuiButtonBase-root.MuiButton-root.MuiButton-text.contct_us_button {
        border-radius: 8px !important;
        padding: 16px 0px !important;
        font-size: 16px !important;
        font-weight: 700 !important;
        width: 250px !important;
        height: 54px;
        color: #FFFFFF !important;
        background-color: #E11B23 !important;
    }

    .faq-contact-main-component {
        font-family: 'Barmeno', sans-serif;
    }

    .contactusFormContainer {
        background-color: white;
        width: 41.7%;
        padding-inline: 24px;
        margin-bottom: 108px;
        border-radius: 8px;
        min-width: 360px;
    }

    .contactus_field__wrapper .react-tel-input {
        font-family: 'Barmeno', sans-serif !important;
        font-size: 16px !important;
        position: relative !important;
        width: 100% !important;
        margin-top: 0px !important;
    }

    .wingo-link {
        margin: 0px !important;
        padding: 8px 6px !important;
        font-family: Barmeno, Sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        text-transform: none;
        color: #747474 !important;
    }

    .contact_form-field,
    .contact_form-field:focus {
        height: 56px;
        width: 100%;
        border-radius: 8px;
        border: 1px solid black;
        padding: 0 10px;
        outline: none;
        background-color: white;
        font-weight: 500;
        box-sizing: border-box;
    }

    .contactus_field__wrapper .form-control,
    .field__wrapper .form-control:focus {
        height: 56px !important;
        width: 100% !important;
        border-radius: 8px !important;
        outline: none;
        border: 1px solid #313131;
    }

    .contactus_field__wrapper label {
        color: #475569;
    }

    .word-count {
        text-align: right;
        margin: 0px;
        font-size: 12px !important;
        font-style: normal !important;
        font-weight: 500 !important;
    }

    .contactus_field__wrapper .errors {
        position: relative;
        font-size: 12px;
        color: #E42328;
        font-weight: 500;
        letter-spacing: 1px;
        margin: 0px;
    }

    .error-input,
    .error-input:focus {
        height: 56px;
        width: 100%;
        border: 1px solid #E42328;
        border-radius: 8px;
        padding: 0 10px;
        outline: none;
        font-weight: 500;
        box-sizing: border-box;
        margin: 0px !important;
    }

    .popup_customform_faq .MuiDialog-paperWidthSm {
        max-width: 600px;
        width: 100%;
        min-height: auto;
        max-height: 600px !important;
    }

    .submitButtonDisabledCon {
        border-radius: 8px !important;
        padding: 16px 0px !important;
        font-size: 16px !important;
        font-weight: 700 !important;
        width: 100% !important;
        height: 54px;
        color: #909090 !important;
        background-color: #BFBFBF !important;
    }

    .submitButtonCon {
        border-radius: 8px !important;
        padding: 16px 0px !important;
        font-size: 16px !important;
        font-weight: 700 !important;
        width: 100% !important;
        height: 54px;
        color: #FFFFFF !important;
        background-color: #E11B23 !important;
    }

    .contactus_field__wrapper .errors {
        position: relative !important;
        font-size: 12px;
        color: #E42328;
        font-weight: 500;
        letter-spacing: 1px;
        min-width: 360px;
        width: 43%;
        margin: 0px;
    }

}

.search #outlined-basic:focus-visible {
    border: none !important;
}

.MuiContainer-root.search.MuiContainer-maxWidthMd {
    display: flex;
    justify-content: center;
}

.contactus_field__wrapper .flag-dropdown {
    border: 1px solid #313131;
}

.contactus_field__wrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;
    font-size: 16px;
    font-weight: 500;
}

.contacusform {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.faqConHeading {
    margin-bottom: 0px;
    font-size: 24px;
}

.faqConContent {
    font-size: 20px;
    font-weight: 500;
}

.mainContactusDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 36px;
    margin-bottom: 108px;
}

.wordCount_error_container {
    display: flex;
    justify-content: flex-end;
    flex-direction: row-reverse;
    justify-content: space-between;
}

@media screen and (min-width: 600px) {
    .formContainerContactus {
        background-color: #FFF;
        border-radius: 8px;
        padding: 24px;
        width: 100%;
        max-width: calc(100% - 792px);
        min-width: 500px
    }
}

@media screen and (max-width: 599px) {
    .formContainerContactus {
        background-color: #FFF;
        border-radius: 8px;
        padding: 24px;
        width: 100%;
        max-width: calc(100% - 68px);
        min-width: calc(100% - 68px);
    }
}