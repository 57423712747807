.flag_area_popup__wrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.flag_area_popup__wrapper > img {
  height: 256px;
  aspect-ratio: 1 / 1;
  object-fit: contain;
}

.flag_area_popup__content {
  display: flex;
  flex-direction: column;
  gap: 4px;
  text-align: center;
}

.flag_area_popup__content > h2 {
  margin: 0;
  font-size: 24px;
  font-weight: 700;
}

.flag_area_popup__content > p {
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  color: #747474;
}

.flag_area_popup__input__label {
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: 700;
}

.flag_area_popup__buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}
