.track_order {
    padding: 0 24px;
}

.id_time {
    display: flex;
    justify-content: space-between;
}

.track_order .title {
    font-family: 'Barmeno', sans-serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 28.8px;
    padding: 12px 0;
    color: #1D1D1D;
}

.id_time .track_id_on {
    display: flex;
    flex-direction: column;
    gap: 2px;
    padding: 12px 0;
}

.id_time .track_id_on .id_code {
    font-family: 'Barmeno', sans-serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 28.8px;
    color: #1D1D1D;
}

.id_time .track_id_on .date_time {
    font-family: 'Barmeno', sans-serif !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    color: #747474;
}

.call_button {
    margin: 0 0 0 auto;
}

.scooter_icon {
    width: 48px;
    height: 48px;
}

.placed_icon {
    width: 100%;
}

.time_price {
    padding: 16px 12px;
    display: flex;
    justify-content: space-between;
    border-bottom: 4px solid #D4D4D4;
}
.order_status_container {
    padding: 0px 0px 0px 0px;
}

.order_status_container .order_status_heading {
    font-family: 'Barmeno', sans-serif;
}

.time_price .estimated_delivery_time {
    padding: 0px;
    font-family: 'Barmeno', sans-serif;
}

.time_price .amount {
    font-family: 'Barmeno', sans-serif;
}

.icons {
    display: flex;
    flex-direction: column;
}

.divider {
    width: 2px;
    height: 26px;
    border-left: 2px dotted #2C6F37;
    margin: 0 auto 0 12px;
}

.amount {
    font-family: 'Barmeno', sans-serif !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 16px !important;
    color: #747474;
}

.home , .pickup {
    font-family: "Barmeno", sans-serif !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 14px !important;
}

.pickup {
    flex: 1;
}

.rider_details {
    display: flex;
    gap: 12px;
    justify-content: center;
    align-items: center;
    margin: 24px 40px 24px 40px;
    padding: 30px 0;
    border-radius: 8px;
    border: 2px solid #409046;
}

.rider_details .delivery_status {
    width: 274px;
    font-family: "Barmeno", sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 21.6px;
    color: #747474;    
}