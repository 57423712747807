.unsubscribe_reason {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.unsubscribe_reason .label {
    font-family: "Barmeno", sans-serif !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    line-height: 16px !important;
    color: rgba(116, 116, 116, 1) !important;
    margin: 3px 0 0 0;
    overflow: visible !important;
}

#reason_drop .MuiOutlinedInput-root {
    font-family: "Barmeno", sans-serif !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    line-height: 16px !important;
    height: 56px !important;
    color: rgba(35, 31, 32, 1) !important;
}

.unsubscribe_reason fieldset {
    border: 2px solid rgba(35, 31, 32, 1) !important;
    border-radius: 8px !important;
}

.unsubscribe_reason .MuiInputBase-input::placeholder {
    color: rgba(116, 116, 116, 1) !important;
    opacity: 1;
    font-family: "Barmeno", sans-serif !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 16px !important;
}

#reason {
    min-height: auto;
}

.unsubscribe_reason .question {
    font-family: "Barmeno", sans-serif !important;
    font-size: 24px !important;
    font-weight: 700 !important;
    line-height: 28.8px !important;
}

.btns {
    margin: 16px 0 0 0;
    display: flex;
    justify-content: center;   
    gap: 40px;
    text-transform: uppercase;
}

.btns .cancel {
    border: 1px solid rgba(225, 27, 34, 1);
    background-color: rgba(255, 255, 255, 1);
    border-radius: 8px !important;
    color: rgba(225, 27, 34, 1) !important;
    font-weight: 700 !important;
    height: 56px !important;
    width: 200px !important;
}

.btns .unsubscribe {
    border-radius: 8px;
    background-color: rgba(225, 27, 34, 1) !important;
    color: rgba(255, 255, 255, 1) !important;
    font-family: "Barmeno", sans-serif !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 19.2px !important;
    padding: 18px 50px !important;
    height: 56px !important;
    width: 200px !important;
}