.view_staff__personal_info__header {
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    border-bottom: 1px solid #D4D4D4;
  }
  
  .view_staff__personal_info__header__title {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 16px;
    font-weight: 700;
    color: #1D1D1D;
  }
  