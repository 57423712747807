.sidebar__wrapper {
    display: grid;
    grid-template-columns: 200px 1fr;
    grid-template-rows: repeat(8, 1fr);

    height: 100%;
}

.sidebar__logo_wrapper {
    grid-row: 1 / 2;

    display: flex;
    justify-content: center;
    align-items: center;

    height: 100%;
}

.sidebar__logo {
    width: auto;
    height: 60px;
}

.sidebar__navbar {
    grid-row: 2 / 9;

    padding: 16px 0;

    display: flex;
    flex-direction: column;
    row-gap: 24px;

    overflow-y: auto;
}

.sidebar__navbar::-webkit-scrollbar {
    width: 4px;
}

.scrollbar__shadow {
    box-shadow: inset 0px -5px 24px -6px rgba(0,0,0,0.15)
}

.nav__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 8px;

    position: relative;
    height: 74px;

    cursor: pointer;
}

.nav__item[data-disabled="true"] {
    cursor: not-allowed;
}

.nav__active {
    position: absolute;
    left: 0;
    width: 8px;
    height: 100%;

    background-color: #E11B22;
    border-radius: 0 8px 8px 0;
}

.nav__item__icon {
    width: 48px;
    height: 48px;
}

.nav__item__icon[data-disabled="true"] {
    filter: grayscale(100);
}

.nav__item__label {
    font-size: 14px;
    font-weight: 500;
    color: #747474;
    text-align: center;
}

.sidebar__footer {
    grid-row: 7 / 9;

    padding: 16px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    place-self: center;
}

.sidebar_footer__item {
    padding: 8px 4px;
    width: 100%;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 8px;

    color: #1D1D1D;

    cursor: pointer;
}

.sidebar_footer__item.location {
    color: #747474;
}

.sidebar_footer__item.logout {
    color: #E11B22;
}

.sidebar_footer__icon {
    width: 24px;
    height: 24px;
}

.sidebar_footer__label {
    font-size: 14px;
    font-weight: 700;
}
