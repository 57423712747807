.dashboard__header {
    width: 100%;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    column-gap: 16px;
}

.header__item {
    display: flex;
    align-items: center;
    column-gap: 16px;
}

.header__item.end {
    place-self: center;
    justify-self: end;
}

.header__title {
    font-size: 24px;
    font-weight: 700;
    color: #1d1d1d;
}

.header__select {
    min-width: 200px;
    border-radius: 8px !important;
    font-family: inherit !important;
}

.header__select__input {
    padding: 12px !important;
    padding-right: 32px !important;

    display: flex !important;
    align-items: center;
    gap: 8px;

    border-color: #2C6F37 !important;
}

.header__select__menu {
    margin-top: 8px;
    border-radius: 8px !important;
    font-family: "Barmeno", sans-serif !important;
}

.header__select__list {
    padding: 0px !important;
}

.header__select__item {
    padding: 0px 12px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: flex-start !important;
    grid-gap: 8px;
    gap: 8px;
    font-family: "Barmeno", sans-serif !important;
}

.header__role_selection__icon {
    width: 16px !important;
    height: 16px !important;
    color: #E11B22;
    fill: #E11B22;
}

.header__select__item:hover {
    background-color: rgba(44, 111, 55, 0.5) !important;
}

.header__select__item.selected {
    background-color: #2C6F37 !important;
    color: #fff !important;
    fill: #fff !important;
}
