.add_address__wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.add_address__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  border-bottom: 1px solid #D4D4D4
}

.add_address__content {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.add_address__tab__wrapper {
  padding: 2px !important;
  width: fit-content;
  border: 1px solid #1D1D1D;
  border-radius: 50px;
}

.add_address__tab__hidden_indicator {
  display: none;
}

.add_address__tab {
  padding: 10px 12px !important;
  min-width: 148px !important;
  color: #909090 !important;
  text-transform: capitalize !important;
}

.add_address__tab__selected {
  padding: 10px 12px !important;
  min-width: 148px !important;
  background-color: #1D1D1D !important;
  color: #FFFFFF !important;
  text-transform: capitalize !important;
  border-radius: 50px !important;
}

.add_address__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.add_address__autocomplete__menu__item {
    padding: 8px 16px !important;
    width: 100%;
    font-size: 14px !important;
    font-family: "Barmeno", sans-serif !important;
    text-overflow: ellipsis;
    overflow: clip;
    align-items: center !important;
    justify-content: flex-start !important;
    border-radius: 8px !important;
}

.add_address__map {
    width: 100%;
    height: 396px;
    border-radius: 8px;
    overflow: clip;
}

.add_address__postcode__options_list {
    padding: 8px 0 !important;
    max-height: 156px !important;
    display: flex;
    flex-direction: column;
    grid-gap: 4px;
    gap: 4px;
    align-items: flex-start;
    border-radius: 8px !important;
    box-shadow: 0px 2px 8px 0px #00000014;
    overflow-y: auto;
}
