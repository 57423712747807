.react-datepicker__day.react-datepicker__day--001.react-datepicker__day--selected {
  border-radius: 50%;
  background-color: #F6B8BA;
  color: #CD191F;
}

.react-datepicker__month-container {
  font-family: Barmeno, sans-serif;
}

.react-datepicker__header {
  background-color: white;
  border: 0px;
  font-family: Barmeno, sans-serif;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
  border-color: #ccc;
  border-style: solid;
  border-width: 2px 2px 0 0 !important;
  content: "";
  display: block;
  height: 6px !important;
  position: absolute;
  top: 6px;
  width: 6px !important;
}

.react-datepicker__day-name{
  color: #909090 !important;
  font-weight: 700 !important;
}

.react-datepicker__current-month {
  font-size: 14px !important;
  font-weight: 700;
  color: #313131;
}

.react-datepicker__day.react-datepicker__day.react-datepicker__day--selected {
  border-radius: 50%;
  border: 1px solid #F19699;
  background-color: #F6B8BA;
  color: #CD191F;
  font-weight: 700;
  font-size: 14px;
}

.react-datepicker__day.react-datepicker__day.react-datepicker__day--keyboard-selected.react-datepicker__day--today {
  border-radius: 50%;
  border: 1px solid #F19699;
  background-color: #F6B8BA;
  color: #CD191F;
  font-weight: 700;
  font-size: 14px;
}

.react-datepicker__day.react-datepicker__day {
  font-weight: 700;
  color: #313131;
}

.react-datepicker__day.react-datepicker__day.react-datepicker__day--outside-month {
  color: #A2A2A2;
}

.react-datepicker__day-name {
  color: #909090;
  font-weight: 500;
  font-size: 14px;
}

.react-datepicker__header {
  border: 0px !important;
  background-color: white !important;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after {
  border: 0px solid transparent !important;
}

.dateCustom {
  width: 100%;
  height: 56px;
  border-radius: 8px;
  border: 1px solid black;
  padding-right: 30px;
  padding-left: 10px;
  font-family: "Barmeno", sans-serif;
  font-weight: 600;
  background-color: transparent;

}

.react-datepicker__day--disabled {
  color: #A2A2A2 !important; /* Change the color to grey */
}

*:focus {
  outline: none;
  border-color: green;
}

.date-picker-container {
  position: relative;
  max-width: 350px;
  z-index: 99;
}

.calendar-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: -1;
}