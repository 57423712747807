.confirm__wrapper {
  padding: 48px 24px;

  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 24px;

  font-family: "Barmeno", sans-serif;
}

.confirm__header {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.confirm__title {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.5;
}

.confirm__description {
  margin: 0;
  font-size: 16px;
  line-height: 1.5;
}

.confirm__buttons {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
}
