@media screen and (min-width:1280px) {

    .main_containers{
        font-family: 'Barmeno', sans-serif;
        width: 100%;
        background-size: cover;
        background-position: center;
    }

    .restaurant_containers{
        display: flex;
        flex-direction: column;
    }

    .restaurant-grid{
        padding: 40px !important;
        display: grid !important;
        justify-items: center !important;
        grid-template-columns: repeat(auto-fill, minmax(calc((80% - 40px) / 3), 1fr));
        gap: 16px;
    }
    
    .restaurant-tile{
        padding: 16px 0;
        background-color: #FFFFFF;
        border-radius: 8px;
        min-height: 250px;
        width: 400px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        position: relative;
    }

    .restaurant_line {
        height: 100px;
        background-color: #2C6F37;
        display: flex;
        font-size: 36px;
        font-weight: 400;
        align-items: center;
        font-family: Verona, sans-serif;
        padding: 0px 100px;
        color: white;
      }
      .location{
        color:#747474
      }
    .restaurant-option {
        padding: 0px 40px;
        box-sizing: border-box;
    }
    .restaurant-content{
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .restaurant-detail{
        display: flex;
        align-items: center;
        gap: 4px;
        font-size: 16px !important;
        font-weight: 500;
        color: #747474;
      }

    .submit-button{
        background-color: #E11B22 !important;
        border-radius: 8px !important;
        color: white !important;
        font-size: 16px !important;
        font-weight: 700 !important;
        width: 140px !important;
        height: 39px !important;
        padding: 10px 16px 10px 16px !important;
        margin-left: 40px !important;
    }
    .MuiDialogContent-root:first-child {
        padding: 20px 40px 20px 40px !important;
    }
    .popup-main{
        min-height: 500px !important;
    }
    .closeIcon{
        margin-left: 35% !important;
    }
    .restaurant-options{
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 16px;
    }
    .restaurant-contents{
        display: flex;
        flex-direction: column;
        gap: 4px;
    }
    .collection-button{
        width: 93% !important;
        height: 56px !important;
        margin: 0px !important;
        background-color: #E11B22 !important;
        color: #FFFFFF !important;
    }
}

@media screen and (max-width:910px) {
    .restaurant-grid{
        grid-template-columns: repeat(auto-fill, minmax(calc((91% - 40px) / 1), 1fr)) !important;
    }   
}

@media screen and (min-width:300px) and (max-width: 1279px) {

    .main_containers{
        font-family: 'Barmeno', sans-serif;
        width: 100%;
        background-size: cover;
        background-position: center;
    }

    .restaurant_containers{
        display: flex;
        flex-direction: column;
    }

    .restaurant_line {
        height: 100px;
        background-color: #2C6F37;
        display: flex;
        font-size: 36px;
        font-weight: 400;
        align-items: center;
        font-family: Verona, sans-serif;
        padding: 0px 100px;
        color: white;
      }

      .restaurant-grid{
        padding: 40px !important;
        display: grid !important;
        justify-items: center !important;
        grid-template-columns: repeat(auto-fill, minmax(calc((91% - 40px) / 2), 1fr));
        gap: 16px;
    }
    .restaurant-tile{
        background-color: #FFFFFF;
        border-radius: 8px;
        min-height: 250px;
        max-width: 400px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;
        height: 100%;
        padding: 10px;
        box-sizing: border-box;
        position: relative;
    }
    .location{
        color:#747474
      }
    .restaurant-option{
        padding: 0px 40px;
        box-sizing: border-box;
    }
    .restaurant-content{
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .restaurant-detail{
        display: flex;
        align-items: center;
        gap: 4px;
        font-size: 16px !important;
        font-weight: 500;
        color: #747474;
      }
      .submit-button{
        background-color: #E11B22 !important;
        border-radius: 8px !important;
        color: white !important;
        font-size: 16px !important;
        font-weight: 700 !important;
        width: 172px !important;
        height: 59px !important;
        padding: 10px 16px 10px 16px !important;
        margin-left: 40px !important;
    }

    .MuiDialogContent-root:first-child {
        padding: 20px 40px 20px 40px !important;
    }
    .popup-main{
        min-height: 500px !important;
    }
    .closeIcon{
        margin-left: 35% !important;
    }
    .restaurant-options{
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 16px;
    }
    .restaurant-contents{
        display: flex;
        flex-direction: column;
        gap: 4px;
    }
    .collection-button{
        width: 93% !important;
        height: 56px !important;
        margin: 0px !important;
        background-color: #E11B22 !important;
        color: #FFFFFF !important;
    }
}

.container_storelocation_popup{
    display: flex;
    flex-direction: column;
    padding-bottom: 24px;
    background: #fff;
    font-family: 'Barmeno',sans-serif;
    gap: 24px;
}
.select_restaurant_heading_popup{
    font-weight: 700;
    font-size: 24px;
    text-align: center;
}
.action_container_popup_store{
    display: flex;
    flex-direction:column;
    gap:16px;
}
.popup_postalcode_fields{
    display: flex;
    flex-direction: column;
    gap: 4px;
    position: relative;
}
.collection_button_store_popup{
    width: 100% !important;
    height: 56px !important;
    margin: 0px !important;
    background-color: #E11B22 !important;
    color: #FFFFFF !important;
}
.storeLocationInput fieldset{ 
    border:none
}
input:-internal-autofill-selected {
    background-color: transparent !important;
}
.storeLocationInput .MuiOutlinedInput-input {
    padding: 0px;
}
.no_result_restaurant{
    padding: 0px 40px 0px 40px;
    background: #FFFFFF;
    margin: 40px 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 24px;
    border-radius: 8px;
    height: 462px;
    font-family: 'Barmeno', sans-serif;
}
.display_noresult_store_items{
    display: flex;
    flex-direction: column;
    gap: 24px;
}
.main_content_no_store{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 20px;
    font-weight: 700;
}
.select_using_postcode_button{
    color: white !important;
    background: #E11B22 !important;
    width: fit-content !important;
    padding: 10px 32px 10px 32px !important;
    box-sizing: border-box !important;
    border-radius: 8px !important;
    align-self: center;
}

.restaurant_title_distance_container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.restaurant_distance_container{
    height: 29px;
    display: flex;
    flex-direction: row;
    gap: 2px;
    align-items: center;
    color: #2C6F37;
    font-family: Barmeno, sans-serif;
    font-size: 18px;
    font-weight: 700;
}

  
.suggestions-dropdown {
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 99;
    background-color: white;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    padding-top: 5px;
    border-bottom: 2px solid;
    border-right: 2px solid;
    border-left: 2px solid;
    width: 100%;
    margin-top: 72px;
    box-sizing: border-box;
}

.suggestions-dropdown-item{
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: baseline;
    padding: 10px;
}
.suggestions-dropdown-item:hover{
    background-color: whitesmoke;
}