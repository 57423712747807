.calendar__login__modal {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.calendar__login__select__root {
  height: 96px;
  aspect-ratio: 1;
  background-color: #f1f1f1;
}

.calendar__login__modal__header {
  padding: 24px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  border-bottom: 1px solid #D4D4D4;
}

.calendar__login__modal__header > h3 {
  margin: 0;
  font-size: 24px;
  font-weight: 700;
}

.calendar__login__modal__body {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.calendar__login__modal__body > h4 {
  margin: 0;
  font-size: 24px;
  font-weight: 700;
}
.calendar__login__modal__login {
  display: flex;
  flex-wrap: wrap;
  column-gap: 48px;
  row-gap: 16px;
}

.calendar__login__modal__login > h5 {
  margin: 0;
  min-width: 96px;
  font-size: 18px;
}

.calendar__login__modal__timer {
  display: flex;
  align-items: center;
  gap: 16px;
}

.calendar__login__timer__separator {
  font-size: 24px;
  font-weight: 700;
}

.calendar__login__select {
  padding: 0 !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-size: 44px !important;
  font-weight: 700 !important;
  font-family: "Barmeno", sans-serif !important;
}

.calendar__login__select__input {
  border: none !important;
}

.calendar__login__select__icon {
  display: none !important;
}

.calendar__login__timer__label {
  margin: 0 !important;
}

.calendar__login__timer__period {
  width: 62px;
  height: 58px;
}

.calendar__login__timer__period.selected {
  color: #6200EE !important;
  background-color: #F2E7FE !important;
}

.calendar__login__timer__checkbox {
  margin-bottom: auto;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: #FAF4F4;
  color: #2C6F37;
  aspect-ratio: 1;
}

.calendar__login__timer__checkbox[data-active="true"] {
  background-color: #2C6F37;
  color: #FAF4F4;
}

.calendar__login__modal__footer {
  padding: 24px 0;
  display: flex;
  align-items: center;
  column-gap: 24px;
}

.calendar__login__modal__footer > button {
  min-width: 256px !important;
  width: 48% !important;
}
