.cataloguepageContainer {
    display: flex;
    flex-direction: row;
    gap: 24px;
    padding: 32px 24px;
}

.catalogueContainer {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 16px;
    background-color: #FFFFFF;
    max-width: 800px;
    width: 100%;
    border-radius: 4px;
}

.shoppingCartContainer {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 16px;
    background-color: #FFFFFF;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
    max-width: 568px;
}

.catalogueHeaderSection {
    display: flex;
    flex-direction: row;
    gap: 24px;
}

.menuIconButton {
    background-color: #E11B22 !important;
    width: 44px;
    height: 44px;
    border-radius: 8px !important;
    padding: 10px !important;
    color: white !important;
}

.catalogueHeaderMenuDealsTabBar {
    width: 200px;
    height: 44px !important;
    min-height: 44px !important;
    border: 1px solid #747474;
    border-radius: 50px;
    padding: 2px;
    box-sizing: border-box;
}

.MuiButtonBase-root.MuiTab-root.MuiTab-textColorInherit.tabsOptionTablist.Mui-selected {
    min-width: 97px !important;
    background-color: #1D1D1D !important;
    border-radius: 50px;
    color: #FFF;
    height: 38px;
    min-height: 38px;
    text-transform: capitalize;
    font-weight: 700 !important;
}

.MuiButtonBase-root.MuiTab-root.MuiTab-textColorInherit.tabsOptionTablist {
    min-width: 97px !important;
    color: #909090;
    height: 38px;
    min-height: 38px;
    text-transform: capitalize;
    font-weight: 700 !important;
}

.catalogueHeaderMenuDealsTabBar .MuiTabs-indicator {
    visibility: hidden;
}

.MuiInputBase-root.MuiOutlinedInput-root.posCatalogueSearchInput.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedStart.MuiOutlinedInput-adornedStart {
    height: 44px;
    padding-right: 16px;
}

.posCatalogueSearchInput .PrivateNotchedOutline-root-20.MuiOutlinedInput-notchedOutline {
    border-radius: 8px;
}

.posCatalogueSearchInput .PrivateNotchedOutline-root-5.MuiOutlinedInput-notchedOutline {
    padding-right: 44px;
  }
  
.catalogueHeaderMenuDealsFilter {
    border: 1px solid #1D1D1D !important;
    border-radius: 4px !important;
    height: 44px;
    width: 90px;
    padding: 12px 0px !important;
    text-transform: none;
    color: #1D1D1D !important;
}

.MuiButtonBase-root.MuiTab-root.MuiTab-textColorInherit.catalogueCategoryContainerTabList.Mui-selected {
    min-width: fit-content !important;
    background-color: #1D1D1D !important;
    background-image: linear-gradient(99.09deg, #F6B8BB 2.64%, #E11B23 100%) !important;
    border-radius: 50px;
    color: #FFF;
    height: 32px;
    min-height: 32px;
    text-transform: capitalize;
    font-weight: 700 !important;
    padding: 8px 16px !important;
}

.MuiButtonBase-root.MuiTab-root.MuiTab-textColorInherit.catalogueCategoryContainerTabList {
    min-width: fit-content !important;
    color: #747474;
    height: 32px;
    min-height: 32px;
    text-transform: capitalize;
    font-weight: 700 !important;
    background-color: #F1F1F1;
    border-radius: 50px;
    padding: 8px 16px !important;
}

.catalogueCategoryContainerTabBar .MuiTabs-flexContainer {
    flex-direction: row;
    gap: 16px;
    height: 32px;
}

.catalogueCategoryContainerTabBar .MuiTabs-indicator {
    visibility: hidden;
}

.MuiTabs-root.catalogueCategoryContainerTabBar {
    min-height: 32px;
    height: 100%;
    max-height: 32px;
}

.catalogueCategoryContainerTabBar .MuiButtonBase-root.MuiTabScrollButton-root.MuiTabs-scrollButtons.MuiTabs-scrollButtonsDesktop {
    background-color: #E11B22;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    color: white;
}

.catalogueItemContainer {
    display: flex;
    flex-direction: column;
    gap: 40px;
    height: 100vh;
    overflow: auto;
}


.categoryContainer {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.categoryHeaderContainer {
    height: 49px;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    font-size: 24px;
    font-weight: 400 !important;
    line-height: 28.8px;
    font-family: Verona, sans-serif;
}

.itemInfoContainer {
    width: 150px;
    border: 1px solid #D4D4D4;
    border-radius: 8px;
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    justify-content: start;
    box-sizing: border-box;
}

.itemImageContainer {
    width: 110px;
    height: 106.87px;
    min-width: 110px;
    min-height: 106.87px;
    border-radius: 4px;
    box-shadow: 0px 4px 40px 0px #0000001A;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.itemInfo {
    display: flex;
    flex-direction: column;
    gap: 12px;
    max-width: 126px;
    width: 100%;
    font-weight: 700;
    font-size: 16px;
}

.itemInfoTitle {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.itemInfoDescription {
    display: flex;
    font-size: 12px;
    font-weight: 500;
    color: #747474;
}

.categoryItemContainer {
    display: grid !important;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-gap: 32px;
}

#categoryHeaderContainer0 {
    background-color: #FFC801CC;
}

#categoryHeaderContainer1 {
    background-color: #7645CCCC;
    color: #FFFFFF;
}

#categoryHeaderContainer2 {
    background-color: #A9D15C;
}

.posCatalogueSearchInput input#outlined-basic:focus-visible {
    border: 0px solid #313131 !important;
  }  
.MuiInputBase-root.MuiOutlinedInput-root.posCatalogueSearchInput.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedStart.MuiOutlinedInput-adornedStart:focus-visible{
    outline: 0px;
}
.MuiFormControl-root.MuiTextField-root.searchField.MuiFormControl-fullWidth:focus-visible{
    outline: 0px;
}


/* product description */

.productDescriptionContainer {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 16px 40px 40px 40px;
    box-sizing: border-box;
}

.productDescriptionHeader{
    display: flex;
    flex-direction: row;
    gap: 24px;
    align-items: center;
    justify-content: space-between;
}

.productDescriptionTitleContainer{
    display: flex;
  flex-direction: column;
  gap: 22px;
  line-height: 40px;
}

.productDescriptionTitle{
    font-size: 30px;
    font-weight: 700;
}

.splitContainer{
    display: flex;
    flex-direction: row;
    gap: 16px;
}

.splitContainerItems{
    display: flex;
  background-color: #F1F1F1;
  padding: 6px 16px;
  border-radius: 8px;
  gap: 6px;
  width: 96px;
  height: 32px;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  color: #909090;
  font-weight: 700;
  font-size: 16px;
  box-sizing: border-box;
  line-height: 19.2px;
  text-wrap: nowrap;
}

.accordianPosProductDescription.MuiPaper-elevation1{
    box-shadow: none !important;
}

.accordianPosProductDescription.MuiAccordion-root::before{
    background-color: transparent !important;
}
#accordianPosProductDescriptionSummary.MuiAccordionSummary-root{
padding: 0px;
min-height: auto !important;
}
.accordianPosProductDescription .MuiAccordionSummary-content.Mui-expanded{
    margin: 0px;
}
#accordianPosProductDescriptionSummary{
    color: #E11B22;
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
}
.MuiPaper-root.MuiAccordion-root.accordianPosProductDescription.Mui-expanded.MuiAccordion-rounded.MuiPaper-elevation1.MuiPaper-rounded{
    margin: 0px;
}

.spiceLevelLabelValuePDSelected,.spiceLevelLabelValuePD{
    display: flex;
  gap: 4px;
  flex-direction: row;
  background-color: #F1F1F1;
  padding: 4px 12px;
  border-radius: 26px;
  width: fit-content;
  font-size: 16px;
  font-weight: 500;
  font-family: "Barmeno",sans-serif;
  height: 44px;
  box-sizing: border-box;
  align-items: center;
  color: #525252;
}

.spiceLevelLabelValuePDSelected{
background-image: linear-gradient(99.09deg, #F6B8BB 2.64%, #E11B23 100%);
color: #fff !important;
}

.spiceImageContainer{
    height: 20px;
  width: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

#accordian_spicelevel_summar .MuiFormControlLabel-root{
    margin: 0px;
}

.form_control_label_choose_yourType {
    height: 62px;
    display: flex;
    flex-direction: row;
    grid-gap: 0px;
    gap: 0px
}

.itemInfoContainer.productDescription{
    justify-content: flex-start;
}

.sideContainerRegSide{
    display: flex;
    flex-direction: column;
    gap:24px;
    padding-top: 24px;
}

.notesAccordainTextArea {
    height: 82px;
    width: 100%;
    border: 1px solid #BFBFBF;
    border-radius: 8px;
    background-color: #FFF;
    padding: 10px;
    font-family: Barmeno,sans-serif;
    font-weight: 700;
    font-size: 16px;
    color: #1D1D1D;
  }
  .noResultFoundContainer {
      display: flex;
      flex-direction: column;
      padding: 40px 0px;
      align-items: center;
      justify-content: center;
      gap: 16px;
  }



.CheckoutHeadingConntainer{
display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.CheckoutHeading{
    font-size: 24px;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.itemContainer{
    display: flex;
    flex-direction: column;
    gap: 24px;
    height: 100%;
}

.noItemsFoundText{
    font-weight: 700;
    font-size: 20px;
    color: #1d1d1d;
}

.priceContainer{
    height: 212px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.buttonContainer{
    display: flex;
    flex-direction: row;
    gap: 24px;
    justify-content: center;

}

.pricesAndCouponContainer{
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 8px 0px;
}

.subTotalContainer {
    display: flex;
    flex-direction: row;
    gap: 8px;
    justify-content: space-between;
    padding: 8px 12px;
    height: 35px;
    box-sizing: border-box;
  }

.subTotalKeyPos{
    color: #747474;
    font-size: 18px;
    font-weight: 700;
}

.subTotalValuepos{
    color: #1D1D1D;
    font-size: 16px;
    font-weight: 700;
}

.getcartItemContainer{
    display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  box-sizing: border-box;
  border-bottom: 1px solid #D4D4D4;
}

.itemDescContainer{
    display: flex;
    flex-direction: row;
    gap: 16px;
    justify-content: space-between;
}

.itemDescPicContainer{
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center
}

.posItemImage{
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
    box-shadow: 0px 4px 40px 0px #0000001A;
    border-radius: 4px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.itemTitlePosCart{
    font-size: 14px;
  font-weight: 700;
  color: #1d1d1d;
}

.itemSidesExtra{
    color:#747474;
    font-size: 14px;
    font-weight: 500;
}

.itemPriceInfo{
    display: flex;
  flex-direction: row;
  gap: 12px;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 20px;
}

.posItemInfo {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

.editQuantityInfo {
    display: flex;
    flex-direction: row;
    gap: 4px;
    justify-content: space-between;
  }
.editAllegenInfo{
    display: flex;
    flex-direction: row;
    gap: 24px;
}
.editAllegenInfo .edit_button{    
    width: 32px;
    height: 32px;
    padding: 6px 4px 6px 4px !important;
    gap: 6px;
    border-radius: 8px !important;
    background-color: #FAF4F4 !important;
    box-shadow: 0px 8px 32px 0px #0000000F;
    margin: 0 0 0 auto !important;
}

.quantityInfo .edit_button{
    width: 32px;
    height: 32px;
    padding: 6px 4px 6px 4px !important;
    gap: 6px;
    border-radius: 8px !important;
    background-color: #BED2C154 !important;
    box-shadow: 0px 8px 32px 0px #0000000F;
    margin: 0 0 0 auto !important;
}

.quantityInfo {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
  }

 .itemCartContainerDiplay{
    height: 632px;
    overflow: scroll;
 }

 .allergenHeader{
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    padding: 24px 0px;
    border-bottom: 1px solid #D4D4D4;
  }
  .HeaderTitleAllergen{
    font-size: 24px;
  font-weight: 700;
  color: #1d1d1d;
  }
  .allergenPopupContainer{
display: flex;
flex-direction: column;
gap: 24px;
  }

  .allergenDescContainer{
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 0px 16px;
  }

  .allergenName {
    width: fit-content;
    padding-right: 10px;
    border-right: 2px solid #D4D4D4;
    color: #747474;
    font-size: 14px;
    font-weight: 700;
  }

  .allergenContainer{
    display: flex;
    flex-direction: row;
    gap: 16px;
  }

  .allergenPopopDialog .MuiPaper-root.MuiDialog-paper.DialogPopup-dialogPaper-12.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded {
    height: fit-content;
  }

  .posOrderAccountName{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    color: #E11B22;
    font-weight: 700;
    font-size: 16px;
  }

  .itemCartCount{
    padding: 2px 4px;
  box-sizing: border-box;
  border-radius: 50%;
  background-color: #2C6F37;
  width: 24px;
  height: 24px;
  font-size: 12px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  }