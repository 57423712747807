.scheduler__header {
  padding: 0 0 8px 0;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.scheduler__header__top {
  display: flex;
  flex-direction: column;
}

.scheduler__header__left {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 16px;
  row-gap: 4px;
}

.scheduler__header__left__title {
  font-size: 20px;
  font-weight: 700;
  color: #1D1D1D;
}

.scheduler__header__left__total_hours {
  padding: 8px;
  background-color: #FEF3C7;
  line-height: 1.25;
  border-radius: 8px;
}

.scheduler__header__left__error {
  padding: 4px 8px;
  height: 36px;
  display: flex;
  align-items: center;
  background-color: #FEE2E2;
  color: #E42328;
  font-size: 12px;
  font-weight: 500;
  border-left: 4px solid #E42328;
  border-radius: 8px;
}

.scheduler__header__right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  column-gap: 8px;
  row-gap: 4px;
}

.scheduler__header__right__button_group {
  display: flex;
  gap: 4px;
}

.scheduler__header__right__tab {
  display: flex;
  align-items: center;
  justify-content: center;
}

.scheduler_tab {
  padding: 6px 12px;
  background-color: #FFFFFF;
  color: #909090;
  text-transform: uppercase;
  border-radius: 50px;
  cursor: pointer;
}

.scheduler_tab[data-active="true"] {
  background-color: #1D1D1D;
  color: #FFFFFF;
}

.scheduler__content {
  flex: 1;
}

.scheduler__header__footer {
  display: flex;
  align-items: center;
}

.scheduler__header__footer__button {
  padding: 8px !important;
  background-color: #1D1D1D !important;
  color: #FFFFFF !important;
  border-radius: 50px !important;
  cursor: pointer !important;
}

.scheduler__header__footer__button__text {
  font-size: 14px !important;
  font-weight: 500 !important;
}

.scheduler__toolbar__popover {
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-family: "Barmeno", sans-serif;
}

.scheduler__toolbar__popover__wrapper {
  max-height: 500px !important;
  max-width: 500px !important;
  padding-right: 12px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: auto;
}

.scheduler__toolbar__popover__header {
  padding: 8px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  border-bottom: 1px solid #F1F1F1;
}

.scheduler__toolbar__popover__header > h1 {
  margin: 0;
  font-size: 24px;
  font-weight: 700;
}

.scheduler__toolbar__popover__section {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.scheduler__toolbar__popover__section > h2 {
  margin: 0;
  font-size: 16px;
  font-weight: 700;
}

.scheduler__toolbar__popover__list {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.scheduler__toolbar__popover__list > h3 {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
}

.scheduler__toolbar__popover__list > ul {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: #747474;
  list-style-position: inside;
  list-style-type: none;
  /* list-style-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjRTQyMzI4IiB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAyNCAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJtMjIuNyAxNy41LTguMS0xNGMtLjgtMS40LTIuNy0xLjktNC4xLTEuMS0uNS4zLS45LjctMS4xIDEuMWwtOC4xIDE0Yy0uOCAxLjQtLjMgMy4zIDEuMSA0LjEuNS4zIDEgLjQgMS41LjRIMjBjMS43IDAgMy0xLjQgMy0zIC4xLS42LS4xLTEuMS0uMy0xLjVNMTIgMThjLS42IDAtMS0uNC0xLTFzLjQtMSAxLTEgMSAuNCAxIDEtLjQgMS0xIDFtMS01YzAgLjYtLjQgMS0xIDFzLTEtLjQtMS0xVjljMC0uNi40LTEgMS0xczEgLjQgMSAxeiIvPjwvc3ZnPg==); */
}

.scheduler__toolbar__popover__list > ul > li {
  line-height: 1.2;
}

@container scheduler (width > 566px) {
  .scheduler__header__top {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
  }
}
