.wingos_dropzone {
  width: 100%;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #747474;
  border-radius: 8px;
  box-sizing: border-box;
  cursor: pointer;
}

.wingos_dropzone__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.wingos_dropzone__text {
  font-size: 16px;
  font-weight: 500;
  color: #747474;
  text-align: center;
}

.wingos_dropzone__text__highlighted {
  color: #E11B22;
}

.wingos_dropzone_preview {
  padding: 8px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  background-color: #FFFFFF;
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px #00000014;
  box-sizing: border-box;
}

.wingos_dropzone_preview__item__details {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.wingos_dropzone_preview__item {
  width: 100%;
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 16px;
  padding: 8px;
  border-radius: 8px;
  box-sizing: border-box;
}

.wingos_dropzone_preview__item__image {
  width: 48px;
  height: 48px;
  border-radius: 8px;
  object-fit: scale-down;
}

.wingos_dropzone_preview__item__filename {
  max-width: 196px;
  font-size: 16px;
  font-weight: 500;
  color: #1D1D1D;
  text-overflow: ellipsis;
  overflow: hidden;
}

.wingos_dropzone_preview__item__filesize {
  max-width: 196px;
  font-size: 16px;
  font-weight: 500;
  color: #747474;
  text-overflow: ellipsis;
  overflow: hidden;
}
