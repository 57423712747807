.header_image_rewards{
    position: relative;
    z-index: 1;
    width: 100%;
  }
.allContentContainerRewards {
    display: flex;
    flex-direction: column;
    gap: 80px;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0px 100px;
    box-sizing: border-box; 
}

.reward_main_page_container {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    background-size: contain;
    gap: 40px;
}

.how_it_works_reward_container {
    display: flex;
    flex-direction: column;
    gap: 80px;
    align-items: center;
    justify-content: center;
}

.how_it_works_reward_container_heading {
    text-align: center;
    font-family: Verona, sans-serif;
    font-size: 70px;
    line-height: 90px;
    color: #2C6F37;
    font-weight: 400;
}

.how_it_works_reward_container_images_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.image_rewards {
    height: 100%;
    width: 100%;
}

.how_i_redeem_reward_container {
    display: flex;
    flex-direction: column;
    padding: 0px 100px;
    gap: 40px;
}

.how_i_redeem_reward_content {
    font-family: Barmeno, sans-serif;
    font-size: 24px;
    font-weight: 400;
    color: #1D1D1D;
}

.non_signed_in_user {
    text-align: center;
    font-family: Barmeno, sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 60px;
}

.no_points_signed_in_user {
    text-align: center;
    font-family: Barmeno, sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    width: 765px;
}

.chicken_whole {
    position: absolute;
    left: 100px;
    top: 730px;
    height: 145px;
    width: 150px;
}

.join_now_button {
    background-color: rgb(225, 27, 34);
    color: white;
    width: 350px;
    height: 56px;
    padding: 10px 32px;
    border-radius: 8px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    text-transform: uppercase;
    cursor: pointer;
}
.join_now_button_disabled{
    background-color: #BFBFBF;
color: #909090;
width: 350px;
height: 56px;
padding: 10px 32px;
border-radius: 8px;
font-size: 16px;
font-style: normal;
font-weight: 500;
text-transform: uppercase;

}

.join_now_button:focus {
    border-color: transparent;
}

.skeleton-image-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.skeleton_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
    width:100%;
}

.skeleton_container_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0px 0px 40px 0;
    gap: 20px;
}

.skeleton-paragraph-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0px;
    align-items: center;
}

.wingo_rewardpoint_container {
    display: flex;
    flex-direction: column;
    padding: 0px 100px;
}

.rewardPointDisplay {
    width: 100%;
    justify-content: space-between;
    display: flex;
    gap: 10px
}

.wingo_points_label {
    font-size: 1.67vw;
    font-weight: 500;
    font-family: Barmeno, sans-serif;
    color: #000;
}

.wingo_points_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.11vw;
    position: relative;
    bottom: 40%;
}

.spend_guideline {
    display: flex;
    width: 100%;
    justify-content: center;
    height: 120px;
    color: #D97706;
    background-color: #FEF3C7;
    border-left: 4px solid #D97706;
    align-items: center;
    padding: 12px 16px;
    box-sizing: border-box;
    font-size: 24px;
    font-family: Barmeno, sans-serif;
    font-weight: 700;
}

.enter_wingo_reward_points_label {
    color: #747474;
    font-family: Barmeno, sans-serif;
    font-weight: 500;
    font-size: 18px;
}

.enter_wingo_reward_points_field {
    height: 56px;
    width: 100%;
    border-radius: 8px;
    border: 1px solid #BFBFBF;
    padding: 10px 8px;
    color: #1D1D1D;
    font-family: Barmeno, sans-serif;
    font-weight: 700;
    font-size: 20px;
}

.point_worth_detail {
    height: 56px;
    padding: 10px 12px;
    box-sizing: border-box;
    width: 100%;
    background: linear-gradient(114.11deg, #409046 25.39%, rgba(25, 152, 35, 0.4) 94.9%);
    font-size: 24px;
    font-family: Barmeno, sans-serif;
    font-weight: 700;
    color: #fff;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 283px;
}

.input_for_wingo_reward {
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-width: 500px;
    width: 100%;
}

.wingo_reward_points_container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 32px;
    width: 100%;
}

.redeem_container {
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: flex-start;
    width: 100%;
}

.rewards_guideline_container {
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: flex-start;
}

.errors_rewards {
    position: absolute;
    font-size: 12px;
    color: #E42328;
    font-weight: 500;
    letter-spacing: 1px;
    margin-top: 5px;
    font-family: Barmeno, sans-serif;
}

.wingo_no_reward_container {
    display: flex;
    justify-content: center;
}