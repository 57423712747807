.headOffice {
    /* Enable scrolling */
    overflow-y: auto; /* Vertical scrolling only */
  
    /* Hide scrollbar */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer/Edge */
  }
  
  /* Hide scrollbar in WebKit browsers */
  .headOffice::-webkit-scrollbar {
    display: none;
  }
  
  /* Optional: For older versions of Chrome */
  /* Hide scrollbar track */
  .headOffice::-webkit-scrollbar-track {
    display: none;
  }
  
  /* Optional: For older versions of Chrome */
  /* Hide scrollbar thumb */
  .headOffice::-webkit-scrollbar-thumb {
    display: none;
  }

  .privacy {
    /* Enable scrolling */
    overflow-y: auto; /* Vertical scrolling only */
  
    /* Hide scrollbar */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer/Edge */
  }
  
  /* Hide scrollbar in WebKit browsers */
  .privacy::-webkit-scrollbar {
    display: none;
  }
  
  /* Optional: For older versions of Chrome */
  /* Hide scrollbar track */
  .privacy::-webkit-scrollbar-track {
    display: none;
  }
  
  /* Optional: For older versions of Chrome */
  /* Hide scrollbar thumb */
  .privacy::-webkit-scrollbar-thumb {
    display: none;
  }
  

  .terms {
    /* Enable scrolling */
    overflow-y: auto; /* Vertical scrolling only */
  
    /* Hide scrollbar */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer/Edge */
  }
  
  /* Hide scrollbar in WebKit browsers */
  .terms::-webkit-scrollbar {
    display: none;
  }
  
  /* Optional: For older versions of Chrome */
  /* Hide scrollbar track */
  .terms::-webkit-scrollbar-track {
    display: none;
  }
  
  /* Optional: For older versions of Chrome */
  /* Hide scrollbar thumb */
  .terms::-webkit-scrollbar-thumb {
    display: none;
  }

  .allergen {
    /* Enable scrolling */
    overflow-y: auto; /* Vertical scrolling only */
  
    /* Hide scrollbar */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer/Edge */
  }
  
  /* Hide scrollbar in WebKit browsers */
  .allergen::-webkit-scrollbar {
    display: none;
  }
  
  /* Optional: For older versions of Chrome */
  /* Hide scrollbar track */
  .allergen::-webkit-scrollbar-track {
    display: none;
  }
  
  /* Optional: For older versions of Chrome */
  /* Hide scrollbar thumb */
  .allergen::-webkit-scrollbar-thumb {
    display: none;
  }