.mainWrapper {
    margin-bottom: 72px;
    font-family: 'Barmeno', sans-serif;
}

.title {
    padding: 0;
    margin: 0;
}

.mainWrapper__headerWrapper {
    min-height: 400px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;

    border-radius: 0 0 0 0;
}

.mainWrapper__headerWrapper__header {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;

    background-position: center;
    background-repeat: no-repeat;
}

.mainWrapper__headerWrapper__header__title {
    color: #fff;
    font-size: 70px;
    font-family: "Verona", sans-serif !important;
    font-weight: 400;
    line-height: 2;
    text-align: center;
}

.mainWrapper__actionsWrapper {
    padding: 8px 0;
}

.mainWrapper__actionsWrapper__group {
    display: flex !important;
    justify-content: flex-end;
    align-items: center;
}

.mainWrapper__notificationWrapper {
    display: flex;
    flex-direction: column;
    column-gap: 72px;
}

.mainWrapper__notificationWrapper__itemWrapper {
    display: flex !important;
    flex-direction: column;
    gap: 24px;
}

.mainWrapper__notificationWrapper__notificationContainer {
    padding: 32px 24px;

    display: flex;
    flex-direction: column;
    gap: 24px;

    background-color: #FAF4F4;
    border-radius: 16px;
}

.mainWrapper__notificationWrapper__notificationContainer__titleWrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.mainWrapper__notificationWrapper__notificationContainer__titleWrapper__notificationContainer__title {
    font-size: 24px;
    font-weight: 700;
    color: #1d1d1d;
}

.mainWrapper__notificationWrapper__notificationContainer__titleWrapper__notificationContainer__separator {
    width: 100%;
    height: 1px;
    background-color: #909090;
    border: 0;
}

.mainWrapper__notificationWrapper__notificationsGroup {
    display: flex;
    flex-direction: column;
    gap: 24px;
}