.scheduler__wrapper > * {
  box-sizing: border-box;
}

.scheduler__wrapper {
  container: scheduler / inline-size;
  padding: 24px 32px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: #FFFFFF;
  border-radius: 8px;
  box-sizing: border-box;
}
