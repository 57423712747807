.add_new_staff__dialog__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .add_new_staff__tabs {
    padding: 4px;
    background-color: #F8FAFC;
    border-radius: 8px;
    min-height: 56px !important;
  }
  
  .add_new_staff__tab__indicator {
    display: none;
  }
  
  .add_new_staff__tab {
    min-height: 56px !important;
    flex-direction: row !important;
    align-items: center !important;
    gap: 8px;
    font-size: 14px !important;
    font-weight: 500 !important;
    text-transform: capitalize !important;
    color: #909090;
  }
  
  .add_new_staff__tab__selected {
    min-height: 56px !important;
    flex-direction: row !important;
    align-items: center !important;
    gap: 8px;
    color: #CD191F !important;
    background-color: #FFFFFF !important;
    border-radius: 8px !important;
  }
  
  .add_new_staff__dialog__content {
    container: dialog-content / inline-size;
  
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  
  .add_new_staff__form__grid {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  
  @container dialog-content (width > 640px) {
    .add_new_staff__form__grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 24px;
    }
  }
  
  .add_new_staff__span {
    grid-column: span 2;
  }
  
  .add_new_staff__checkbox__label {
    font-family: "Barmeno", sans-serif !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    color: #1D1D1D;
  }
  
  .add_new_staff__checkbox__checked {
    color: #2C6F37 !important;
    fill: #2C6F37 !important;
  }
  
  .add_new_staff__attach_visa {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  .add_new_staff__submit_group {
    display: flex;
    justify-content: stretch;
    gap: 24px;
  }
  
  .add_new_staff__reset__button {
    padding: 16px !important;
    width: 100%;
    color: #E11B22 !important;
    border: 1px solid #E11B22 !important;
    border-radius: 8px !important;
  }
  
  .add_new_staff__submit__button {
    padding: 16px !important;
    width: 100%;
    background-color: #E11B22 !important;
    border-radius: 8px !important;
  }
  
  .add_new_staff__label {
    font-family: "Barmeno", sans-serif !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    color: #747474 !important;
  }
  
  .add_new_staff__asterisk {
    color: #E11B22 !important;
  }
  
  .add_new_staff__helper_text {
    font-family: "Barmeno", sans-serif !important;
  }
  
  .add_new_staff__menu {
    margin-top: 4px;
    border-radius: 8px;
  }
  
  .add_new_staff__list {
    padding: 4px !important;
    margin: 4px 0;
  
    display: flex;
    flex-direction: column;
  
    border-radius: 8px !important;
  }
  
  .add_new_staff__list_item {
    padding: 4px !important;
    border-radius: 8px !important;
  }
  
  .add_new_staff__list_item_text {
    font-family: "Barmeno", sans-serif !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    color: #1D1D1D !important;
  }
  
  .add_new_staff__backend_errors {
    padding: 16px;
    background-color: rgba(225, 27, 34, 0.05);
    border-radius: 8px;
    list-style-position: inside;
    list-style-type: none;
  }
  
  .add_new_staff__backend_error {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .add_new_staff__backend_error_icon {
    color: #E11B22;
  }
  
  .add_new_staff__backend_error_text {
    font-family: "Barmeno", sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: #E11B22;
    line-height: 1;
    vertical-align: middle;
  }
  .staffInformation__tab__wrapper {
    padding: 2px !important;
    border: 1px solid #1D1D1D;
    border-radius: 50px;
    width: fit-content; /* Restrict the wrapper to fit the content */
    /* margin: auto; Center align if needed */
  }
  .staffInformation__tab__hidden_indicator {
    display: none;
  }

.staffInformation__tab {
    padding: 10px 12px !important;
    min-width: 148px !important;
    color: #909090 !important;
    text-transform: capitalize !important;
  }
  
  .staffInformation__tab__selected {
    padding: 10px 12px !important;
    min-width: 148px !important;
    background-color: #1D1D1D !important;
    color: #FFFFFF !important;
    text-transform: capitalize !important;
    border-radius: 50px !important;
  }
  
  

  
































  /* .staffInformation__tab__wrapper {
    padding: 2px !important;
    border: 1px solid #1D1D1D;
    border-radius: 50px;
    width: fit-content; 
    margin: auto;
  } */
  
  /* .staffInformation__tab {
    padding: 10px 12px !important;
    color: #909090 !important;
    text-transform: capitalize !important;
    font-size: 14px !important; 
  } */
  
  /* .staffInformation__tab__selected {
    padding: 10px 12px !important;
    background-color: #1D1D1D !important;
    color: #FFFFFF !important;
    text-transform: capitalize !important;
    border-radius: 50px !important;
  } */
  