@media screen and (min-width:1280px) {
    .main-component {
        background-color: transparent;
        font-family: 'Barmeno', sans-serif;
    }

    .custom-form__container {
        padding: 40px;
        display: grid;
        align-items: start;
        grid-template-columns: 200px 1fr;
        grid-gap: 40px;
    }

    .custom-form__tag {
        background-color: white;
        padding: 0px 10px;
        border-radius: 8px;
        height: 59px;
        max-width: 277;
        width: 100%;
    }

    .tag-heading {
        display: flex;
        align-items: center;
        height: 22px;
        padding-left: 10px;
        border-left: 1px solid red
    }

    .main-form__container {
        background-color: white;
        box-shadow: 2px 2px 8px gray;
        padding: 10px 40px;
        border-radius: 8px;
        margin-left: 20px;
    }

    .form__wrapper {
        width: 100%
    }

    .field__wrapper label {
        color: #747474;
    }

    .required {
        color: red;
    }

    .field__wrapper .form-control,
    .field__wrapper .form-control:focus {
        height: 56px !important;
        width: 100% !important;
        border-radius: 8px !important;
        outline: none;
        border: 1px solid #313131;
    }

    .form-heading {
        font-size: 24px;
        font-weight: 700;
    }

    .form-content {
        font-size: 18px;
        font-weight: 500;
    }

    .errors {
        position: relative;
        font-size: 12px;
        color: #E42328;
        font-weight: 500;
        letter-spacing: 1px;
    }

    .field__wrapper .flag-dropdown {
        border: 1px solid #313131 !important;
    }

    .submitButton {
        border-radius: 8px !important;
        padding: 16px 0px !important;
        font-size: 16px !important;
        font-weight: 700 !important;
        width: 100% !important;
        height: 54px;
        color: #FFFFFF !important;
        background-color: #E11B23 !important;
    }

    .submitButtonDisabled {
        border-radius: 8px !important;
        padding: 16px 0px !important;
        font-size: 16px !important;
        font-weight: 700 !important;
        width: 100% !important;
        height: 54px;
        color: #909090 !important;
        background-color: #BFBFBF !important;
    }

    .progress-image {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .radio-container {
        display: flex;
        flex-direction: column;
    }

    .radio-container .radio-label {
        display: flex;
        align-items: center;
        margin-right: 20px;
    }

    .radio-container input[type="radio"] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 20px;
        height: 20px;
        border: 1px solid #000;
        border-radius: 6px;
        margin-right: 8px;
    }

    .radio-container input[type="radio"]:checked {
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Crect width='24' height='24' rx='6' fill='%232C6F37'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M18.0718 6.50666C18.6204 6.87856 18.7636 7.62473 18.3917 8.1733L12.2902 17.1733C12.091 17.4672 11.7718 17.6578 11.4185 17.6938C11.0652 17.7297 10.7142 17.6074 10.4598 17.3596L5.76124 12.7841C5.28644 12.3218 5.27636 11.562 5.73873 11.0872C6.2011 10.6124 6.96083 10.6024 7.43564 11.0647L11.1077 14.6406L16.4052 6.82655C16.7771 6.27799 17.5232 6.13477 18.0718 6.50666Z' fill='white'/%3E%3C/svg%3E");
        width: 20px;
        height: 20px;
        border: 0px;
    }

    .partner-details,
    .partner-details:focus {
        margin-top: 10px;
        width: 100%;
        min-height: 104px;
        resize: vertical;
        padding: 8px;
        font-family: 'Barmeno', sans-serif;
        font-weight: 500;
        font-size: 16px;
    }

    .MuiPaper-rounded {
        border-radius: 16px;
    }

    .MuiDialogContent-root:first-child {
        padding: 40px !important;
    }

    .popoup-content {
        height: 100%;
    }

    .form-field option:hover {
        background-color: var(--option-hover-color);
    }

    .popup_customform_franchise .MuiDialog-paperWidthSm {
        max-width: 600px;
        min-height: 555px;
    }

    .word-count {
        text-align: right;
        margin-top: 5px;
        font-size: 12px !important;
        font-style: normal !important;
        font-weight: 500 !important;
        margin-right: 10px !important;
    }

    select::-moz-select-arrow {
        /* For Firefox browsers */
        margin-left: 10px;
        /* Move the arrow to the right */
    }
}

@media screen and (min-width:300px) and (max-width: 1279px) {

    .main-component {
        background-color: transparent;
        font-family: 'Barmeno', sans-serif;
    }

    .custom-form__container {
        padding: 40px;
        display: grid;
        align-items: start;
        grid-template-columns: 100% 1fr;
        grid-gap: 40px;
    }

    .custom-form__tag {
        background-color: white;
        padding: 0px 10px;
        border-radius: 8px;
        height: 59px;
        max-width: 277;
        width: 100%;
        display: none;
    }

    .img2,
    .img1 {
        width: 100%;
    }

    .progress-image {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .tag-heading {
        display: flex;
        align-items: center;
        height: 22px;
        padding-left: 10px;
        border-left: 1px solid red
    }

    .main-form__container {
        background-color: white;
        box-shadow: 2px 2px 8px gray;
        padding: 10px 40px;
        border-radius: 8px;
        margin-left: 20px;
    }

    .form__wrapper {
        width: 100%
    }

    .field__wrapper label {
        color: #747474;
    }

    .required {
        color: red;
    }

    .field__wrapper .form-control,
    .field__wrapper .form-control:focus {
        height: 56px !important;
        width: 100% !important;
        border-radius: 8px !important;
        outline: none;
        border: 1px solid #313131;
    }

    .form-heading {
        font-size: 24px;
        font-weight: 700;
    }

    .form-content {
        font-size: 18px;
        font-weight: 500;
    }

    .errors {
        position: relative;
        font-size: 12px;
        color: #E42328;
        font-weight: 500;
        letter-spacing: 1px;
    }

    .field__wrapper .flag-dropdown {
        border: 1px solid #313131 !important;
    }

    .submitButton {
        border-radius: 8px !important;
        padding: 16px 0px !important;
        font-size: 16px !important;
        font-weight: 700 !important;
        width: 100% !important;
        height: 54px;
        color: #FFFFFF !important;
        background-color: #E11B23 !important;
    }

    .submitButtonDisabled {
        border-radius: 8px !important;
        padding: 16px 0px !important;
        font-size: 16px !important;
        font-weight: 700 !important;
        width: 100% !important;
        height: 54px;
        color: #909090 !important;
        background-color: #BFBFBF !important;
    }

    .radio-container {
        display: flex;
        flex-direction: column;
    }

    .radio-container .radio-label {
        display: flex;
        align-items: center;
        margin-right: 20px;
    }

    .radio-container input[type="radio"] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 20px;
        height: 20px;
        border: 1px solid #000;
        border-radius: 6px;
        margin-right: 8px;
    }

    .radio-container input[type="radio"]:checked {
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Crect width='24' height='24' rx='6' fill='%232C6F37'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M18.0718 6.50666C18.6204 6.87856 18.7636 7.62473 18.3917 8.1733L12.2902 17.1733C12.091 17.4672 11.7718 17.6578 11.4185 17.6938C11.0652 17.7297 10.7142 17.6074 10.4598 17.3596L5.76124 12.7841C5.28644 12.3218 5.27636 11.562 5.73873 11.0872C6.2011 10.6124 6.96083 10.6024 7.43564 11.0647L11.1077 14.6406L16.4052 6.82655C16.7771 6.27799 17.5232 6.13477 18.0718 6.50666Z' fill='white'/%3E%3C/svg%3E");
        width: 20px;
        height: 20px;
        border: 0px;
    }

    .partner-details,
    .partner-details:focus {
        margin-top: 10px;
        width: 100%;
        min-height: 104px;
        resize: vertical;
        padding: 8px;
        font-family: 'Barmeno', sans-serif;
        font-weight: 500;
        font-size: 16px;
    }

    .MuiPaper-rounded {
        border-radius: 16px;
    }

    .MuiDialogContent-root:first-child {
        padding: 40px !important;
    }

    .popoup-content {
        height: 100%;
    }

    .form-field option:hover {
        background-color: var(--option-hover-color);
    }

    .popup_customform_franchise .MuiDialog-paperWidthSm {
        max-width: 600px;
        min-height: 555px;
    }

    .word-count {
        text-align: right;
        margin-top: 5px;
        font-size: 12px !important;
        font-style: normal !important;
        font-weight: 500 !important;
        margin-right: 10px !important;
    }

    select::-moz-select-arrow {
        margin-left: 10px;
    }
}

.feedback-icon {
    rotate: 90deg;
    position: relative;
    height: 24px;
    width: 24px;
}

.feedback-btn-content {
    rotate: 270deg;
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 8px;
    justify-content: center;
    align-items: center;
}

.feedback-btn {
    height: 146px;
    width: 44px;
    border-radius: 8px 0px 0px 8px;
    background-color: #E11B22;
    color: #FFF;
    border: 0px;
    z-index: 9999;
    position: fixed;
    top: 50%;
    right: 0;
    cursor: pointer;
}

.good-icon {
    width: 64px;
    height: 64px;
}

.happy-icon {
    width: 64px;
    height: 64px;
    background-image: url("./../assets/happy-inactive.png");
}

.moderate-icon {
    width: 64px;
    height: 64px;
}

.sad-icon {
    width: 64px;
    height: 64px;
}

.feedback-wrapper {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;

}

body.feedback-container-active {
    background-color: rgba(189, 189, 189, 0.9);
}

.feedback-heading {
    margin-top: -28px;
    position: relative;
    top: 0px;
    left: 86px;
    font-size: 25px;
}

.feedback-container {
    position: fixed;
    top: 109%;
    left: 31%;
    padding: 5rem 7rem;
    translate: translate(-50%, -50%);
    max-width: 30rem;
    background-color: #FFFFFF;
}

.feedBackPopupBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 40px;
}

.feedBackHeading {
    font-family: Barmeno, sans-serif;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
}

.feedBackPopupEmojiContainer {
    display: flex;
    flex-direction: row;
    gap: 30px;
}

.feedback_form_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 14px;
    font-family: "Barmeno", sans-serif;
}

.form_feedback_field {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.input_form_feedback_field {
    width: 472px;
    height: 56px;
    border-radius: 8px;
    border: 2px solid #231F20;
    padding: 10px 8px;
    font-family: Barmeno, sans-serif;
    font-weight: 500;
    font-size: 16px;
}

.closeIconSubmittedFeedback {
    display: flex;
    padding: 16px;
    justify-content: flex-end;
}

.field__wrapper .react-tel-input .form-control {
    border: 1px solid black;
    font-size: 16px;
    font-family: Barmeno, sans-serif !important;
}

.field__wrapper .react-tel-input {
    font-family: 'Barmeno', sans-serif !important;
    font-size: 16px !important;
    position: relative !important;
    width: 100% !important;
    margin-top: 0px !important;
}

.field__wrapper .error-input,
.field__wrapper .error-input:focus {
    height: 56px;
    width: 100%;
    border: 1px solid #E42328;
    border-radius: 8px;
    padding: 0 10px;
    outline: none;
    font-weight: 500;
    margin: 0px;
    box-sizing: border-box;
    font-size: 16px;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.field__wrapper {
    font-size: 16px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.form-field,
.form-field:focus {
    height: 56px;
    width: 100%;
    border-radius: 8px;
    border: 1px solid black;
    padding: 0 10px;
    outline: none;
    background-color: white;
    font-weight: 500;
    box-sizing: border-box;
    font-size: 16px;
}

.wordCount_error_container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
}

.userTypeSelection{
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

.userTypeSelection .form-control, .userTypeSelection .form-control:focus {
  height: 56px !important;
  width: 350px !important;
  border-radius: 8px !important;
  outline: none;
  border: 1px solid #313131;
}

.select-items {
    border-radius: 8px;
    box-shadow: 0px 2px 8px 8px #00000014;
}

.userTypeSelection .select-selected-disabled {
    font-family: 'Barmeno', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    color: #a2a2a2;
}

.userTypeSelection .select-selected-disabled:after {
    transform: rotate(315deg) !important;
    top: 24px !important;
}

.userTypeSelection .select-selected:after {
    position: absolute;
    top: 24px;
    right: 15px;
    content: "";
    display: inline-block;
    width: 6px;
    height: 6px;
    border: 0px solid transparent;
    border-right:  1px solid black;
    border-top:  1px solid black;
    transform: rotate(135deg);
}

.userTypeSelection .select-selected {
    font-family: 'Barmeno', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
}

.userTypeSelection .optionSelected {
    font-family: 'Barmeno', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    color: black;
    padding: 12px 16px;
}

.categories-select-selected{
    font-family: 'Barmeno', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    color: #a2a2a2;
}

.categories-select-selected-before{
    font-family: 'Barmeno', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
}

.select-selected:after {
    position: absolute;
    top: 18px;
    right: 15px;
    content: "";
    display: inline-block;
    width: 6px;
    height: 6px;
    border: 0px solid transparent;
    border-right:  1px solid black;
    border-top:  1px solid black;
    transform: rotate(135deg);
}

.categories-select-selected:after {
    position: absolute;
    top: 20px;
    right: 15px;
    content: "";
    display: inline-block;
    width: 6px;
    height: 6px;
    border: 0px solid transparent;
    border-right:  1px solid black;
    border-top:  1px solid black;
    transform: rotate(315deg);
}
.stockTableCell{
    border-bottom: 0px !important;
    font-family: Barmeno, sans-serif !important;
    padding: 12px 24px !important;
    height: 56px !important;
    font-size: 16px !important;
}

.MuiTableCell-head.stockTableCell {
    font-weight: 700;
}
.MuiTableCell-body.stockTableCell {
    font-weight: 500;
}

.stockRetportStatusDivInStock{
    padding: 2px 8px;
  background-color: #D1FAE5;
  width: fit-content;
  border-radius: 40px;
  color: #059669;
  height: fit-content;
}
.stockRetportStatusDivOutOfStock{
    padding: 2px 8px;
  background-color: #FEE2E2;
  width: fit-content;
  border-radius: 40px;
  color: #DC2626;
  height: fit-content;
}

.addNewStockButton{
    display: flex;
  text-transform: initial;
  width: 160px;
  height: 44px;
  justify-content: center;
  align-items: center;
  padding: 10px 16px !important;
  color: #fff;
  background-color: #E11B22;
  border-radius: 8px;
  border: 0px;
  box-sizing: border-box;
}

.actionDialogStock .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded{
    width: 480px;
  padding: 24px;
  border-radius: 16px;
  box-sizing: border-box;
  font-family: "Barmeno",sans-serif;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.actionDialogStockTitle{
    display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 52px;
  border-bottom: 1px solid #CBD5E1;
}

.actionDialogStockDivDIsplayCol{
    display:flex;
    flex-direction:column;
}

.actionDialogStockDivDIsplayRow{
    display:flex;
    flex-direction:row;
}

.stockActionInput{
    height: 44px;
    border-radius: 8px;
    border: 1px solid #1D1D1D;
    padding: 10px 16px;
    font-size: 16px;
    font-family: Barmeno,sans-serif;
    font-weight: 500;
}

.stockActionLabel{
    font-size: 16px;
  font-weight: 700;
  color: #747474;
}

.actionDialogStockActions{
    display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: center;
  justify-content: center;
}

.cancelStockButton{
    display: flex;
  text-transform: initial;
  width: 160px;
  height: 44px;
  justify-content: center;
  align-items: center;
  padding: 10px 16px !important;
  color: #000A34;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #1D1D1D;
  box-sizing: border-box; 
}