.month__wrapper {
  display: flex;
  flex-direction: column;
}

.month__header {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
  font-weight: bold;
  text-align: center;
  height: 24px;
  border-bottom: 1px solid #ddd;
}

.month__grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 0;
  border: none;
  border-collapse: collapse;
  overflow: hidden;
}

.month__grid__cell {
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 10px 4px;
  overflow: hidden;
  min-height: 196px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

/* Remove right border for the last cell in each row */
.month__grid__cell:nth-child(7n) {
  border-right: none;
}

/* Remove bottom border for cells in the last row */
.month__grid__cell:nth-last-child(-n + 7) {
  border-bottom: none;
}

.month__grid__date {
  font-weight: bold;
  position: absolute;
  top: 5px;
  right: 5px;
}

.month__grid__events {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

button.month__grid__show-more {
  margin-top: 4px !important;
  background-color: transparent;
  text-align: left;
  text-transform: none;
  border: none;
  cursor: pointer;
}

button.month__grid__show-more:hover {
  opacity: 0.75;
}
