.delivery_areas__flagged_icon {
  aspect-ratio: 1;
  width: auto;
  height: 36px;
}

.delivery_areas__flagged_icon[data-flagged="true"] {
  color: rgb(225, 27, 34);
  /* color: #fff; */
}

.delivery_areas__flagged_icon[data-flagged="true"]:hover {
  color: rgb(225, 27, 34);
  /* color: #fff; */
}
