.notificationWrapper {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    gap: 16px;

    padding: 16px 12px;

    font-family: 'Barmeno', sans-serif;
    background-color: #FFFFFF;
    border-left: 3.5px solid #D4D4D4;
    border-radius: 8px;

    box-shadow: 0px 2px 8px 0px #00000014;
}

.notificationWrapper__thumbnail {
    width: 100px;
    height: 100px;

    border-radius: 4px;
    aspect-ratio: 1/1;
    object-fit: contain;
}

.notificationWrapper__content {
    display: flex;
    flex-direction: column;
    gap: 4px;
    flex: 1;
}

.notificationWrapper__content__titleWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
}

.notificationWrapper__content__titleWrapper__title {
    margin: 0;
    
    font-size: 18px;
    line-height: 1.25;
    font-weight: 700;
    overflow: hidden;
}

.notificationWrapper__content__titleWrapper__title__dialog {
    margin: 0;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    
    font-size: 18px;
    line-height: 1.25;
    font-weight: 700;
    overflow: hidden;
}

.notificationWrapper__content__titleWrapper__notReadIndicator {
    height: 12px;
    background-color: #2C6F37;
    border-radius: 50%;
    aspect-ratio: 1/1;
}

.notificationWrapper__content__description {
    margin: 0;

    color: #747474;
    font-size: 16px;
    font-weight: 600;
}

.notificationWrapper__content__cta {
    padding: 0 !important;
    width: fit-content;
    font-weight: 600;
    line-height: 1.5;
    color: #E11B22 !important;
    text-decoration: none;
}

.notificationWrapper__content__cta__icon {
    margin-left: 4px !important;
    width: 20px;
    height: 20px;
    display: flex !important;
    align-items: center;
}

.notificationWrapper__content__cta__text {
    padding: 4px;
    height: fit-content;
    text-transform: none !important;
}

.notificationWrapper__content__time {
    color: #909090;
    font-size: 16px;
    font-weight: 600;
}

.notificationWrapper__contextMenu__paper {
    padding: 0 !important;
    border-radius: 8px !important;
}

.notificationWrapper__contextMenu .MuiList-padding {
    padding: 0 !important;
}

.notificationWrapper__contextMenu {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    gap: 8px;
}

.notificationWrapper__contextMenu__listItem {
    min-width: 224px !important;
}

.notificationWrapper__contextMenu__icon {
    width: 24px !important;
    height: 24px !important;
}

.notificationWrapper__contextMenu__cta {
    aspect-ratio: 1/1;
    width: 36px;
    height: 36px;
}

.notificationWrapper__contextMenu__wrapper {
    padding: 0 !important;
    border-radius: 8px !important;
}

.notificationWrapper__contextMenu__wrapper__error {
    color: #E11B22;
}

.notificationWrapper__contextMenu__icon__wrapper {
    min-width: 36px !important;
}