.deleteCartItem_Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    margin: 0 40px 40px 40px;
}

.deleteCartItem_Container img{
    width: 327px;
    height: 327px;
}

.deleteCartItem_Container .title{
    font-family: "Barmeno", sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 28.8px;
    color: rgba(35, 31, 32, 1);
    text-align: center;
    display: flex;
  justify-content: center;
}

.deleteCartItem_Container .description {
    font-family: "Barmeno", sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 21.6px;
    color: rgba(116, 116, 116, 1);
    margin: 16px 0 0 0;
    text-align: center;
}

.btn_group {
    display: flex;
    justify-content: center;
    gap: 40px;
}

.btn_group .cancel {
    border: 1px solid rgba(225, 27, 34, 1);
    background-color: rgba(255, 255, 255, 1);
    border-radius: 8px !important;
    color: rgba(225, 27, 34, 1) !important;
    font-weight: 700 !important;
    height: 56px !important;
    width: 200px !important;
}

.btn_group .signin {
    border-radius: 8px;
    background-color: rgba(225, 27, 34, 1) !important;
    color: rgba(255, 255, 255, 1) !important;
    font-family: "Barmeno", sans-serif !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 19.2px !important;
    padding: 18px 50px !important;
    height: 56px !important;
    width: 200px !important;
}