.add_address__form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.add_address__form__row {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(256px, 1fr));
  gap: 24px;
}

.add_address__form__input {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.add_address__form__label[data-required="true"]::after {
  content: "*";
  color: red;
}

.add_address__form__checkbox__label {
  font-family: "Barmeno", sans-serif !important;
}

.start-adornment-icon {
  position: absolute;
  left: 16;
  line-height: 1.25;
  z-index: 10;
}
