.date_picker_wrapper {
    width: 100%; /* Ensure the wrapper takes 100% width */
  }
  
  .date_picker_popper {
    width: auto; /* Maintain default behavior for the popper */
  }
  
  .wingo_input {
    width: 100%; /* Ensure the custom input spans full width */
    box-sizing: border-box; /* Include padding and borders in the width */
  }
  