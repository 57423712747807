.modal__wrapper {
  background-color: rgb(0 0 0 / 60%);
}

.modal__container {
  position: relative;
  gap: 16px;
  padding: 24px 40px;
  max-height: calc(100% - 64px) !important;
  font-family: "Barmeno", sans-serif !important;
  border-radius: 8px !important;
  box-shadow: 0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008, 0px 25px 50px 0px #00000017 !important;
}

.wrapper__close_button {
  position: absolute !important;
  top: 16px;
  right: 16px;
}
