.view_staff__wrapper > * {
  box-sizing: border-box;
}

.view_staff__wrapper {
  container: view-staff / inline-size;
  display: flex;
  flex-direction: column;
  gap: 24px;

  font-family: "Barmeno", sans-serif;
}

.view_staff__header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.view_staff__info {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}

.view_staff__info__title {
  font-size: 30px;
  font-weight: 700;
  color: #1D1D1D;
}

.view_staff__info__switch {
  margin: 0 !important;
  gap: 8px;
}

.view_staff__info__label {
  font-size: 24px !important;
  font-weight: 700 !important;
  font-family: "Barmeno", sans-serif !important;
}

.view_staff__section__wrapper {
  padding: 16px 24px;
  max-height: 150vh;
  display: flex;
  gap: 40px;
  background-color: #FFF;
  border-radius: 16px;
}

.view_staff__content {
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow-y: clip;
}

.view_staff__personal_info {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.view_staff__info__wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.view_staff__info___notes {
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow: hidden;
  overflow-y: auto;
}


.view_staff__info__general_info {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.view_staff__info__general_info__title {
  font-size: 16px;
  font-weight: 700;
  color: #1D1D1D;
}

.view_staff__info__general_info__row {
  display: flex;
  gap: 16px;
}

.hidden {
  display: none;
}

.view_staff__info__general_info__row__label {
  max-width: 156px;
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  color: #747474;
}

.view_staff__info__general_info__row__value {
  display: flex;
  flex-direction: column;
  gap: 16px;

  font-size: 18px;
  font-weight: 400;
  color: #1D1D1D;
}

.view_staff__info__general_info__row__value__expiry {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #1D1D1D;
  font-size: 16px;
}

.view_staff__expiry_popup__popover {
  border-radius: 8px !important;
  box-shadow: 0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008 !important;
}

.view_staff__expiry_popup {
  padding: 16px;
  max-width: 256px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-family: "Barmeno", sans-serif;
}

.view_staff__expiry_popup__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  border-bottom: 1px solid #F1F1F1;
}

.view_staff__expiry_popup__title {
  font-size: 16px;
  font-weight: 700;
  color: #1D1D1D;
}

.view_staff__expiry_popup__message {
  padding: 8px 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #747474;
}

.view_staff__activity_log {
  padding: 0 16px 16px 16px;
  width: 30%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: #FFFFFF;
  box-shadow: 0px 2px 8px 0px #00000014;
  border-radius: 8px;
}

.view_staff__log__stack {
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: auto;
  overflow-x: clip;
}

.view_staff__log {
  width: 100%;
  position: relative;
  padding: 0 24px;
  display: flex;
  grid-gap: 16px;
  gap: 16px;
}

.view_staff__log__time {
  min-width: 72px;
  color: #747474;
  font-size: 12px;
  font-weight: 500;
}

.view_staff__log__message_wrapper {
  flex: 1;
  padding: 10px 12px;
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
  background: #F8FAFC;
  border: 1px solid #D4D4D4;
  border-radius: 8px;
  overflow-x: clip;
}

.view_staff__log__message {
  font-size: 12px;
  font-weight: 500;
  color: #1D1D1D;
}

.view_staff__log__change {
  font-size: 12px;
  font-weight: 500;
  color: #747474;
}

.view_staff__log__decorator {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  overflow: clip;
}

.view_staff__log__decorator__circle {
  width: 16px;
  height: 16px;
  border-radius: 50%; /* This creates the outer circle */
  background: linear-gradient(135deg, #409046 0%, rgba(25, 152, 35, 0.4) 100%);
  position: relative;
}

.view_staff__log__decorator__circle::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 6px;  /* Circle with radius 3 */
  height: 6px;
  border-radius: 50%;
  background-color: #F8FAFC; /* Inner white circle */
}

.view_staff__log__decorator__line {
  flex: 1;
  width: 2px;
  height: 100%;
  background: #D4D4D4;
  border-radius: 8px;
}

.view_staff__personal_info.notes {
  padding: 16px;
  border: 1px solid #F1F1F1;
  border-radius: 4px;
  overflow-y: auto;
}

.view_staff__info__note {
  padding: 10px;
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
  border-radius: 8px;
  cursor: pointer;
}

.view_staff__info__note:hover {
  background-color: #F8FAFC;
}

.view_staff__info__note__text {
  color: #747474;
  font-size: 18px;
  font-weight: 700;
}

.view_staff__info__note__timestamps {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  color: #2C6F37;
  font-size: 14px;
  font-weight: 500;
}

@container view-staff (width < 1204px) {
  .view_staff__content {
    width: 60%;
  }

  .view_staff__activity_log {
    width: 40%;
  }
}

@container view-staff (width < 1024px) {
  .view_staff__content {
    width: 100%;
  }

  .view_staff__activity_log {
    width: 100%;
  }
}

@container view-staff (width < 872px) {
  .view_staff__section__wrapper {
    flex-direction: column;
    gap: 24px;
  }

  .view_staff__content {
    width: 100%;
  }

  .view_staff__activity_log {
    padding: 0;
    width: 100%;
  }
}

@container view-staff (width < 720px) {
  .view_staff__info {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
}
