.timer__wrapper {
  padding: 4px 8px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: #FFFFFF;
}

.timer__label {
  font-size: 20px;
  font-weight: 700;
}
