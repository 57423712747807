.gift_container {
    display: grid;
    grid-template-columns: auto auto;
    padding: 100px;
    padding-top: 40px;
    column-gap: 24px;
    row-gap: 21px;
}

.gift_detail {
    grid-row: 1/3;
    background-color: #FFF;
    padding: 24px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 50px;
    max-width: 400px;
    box-shadow: 0px 8px 32px 0px #0000000F;
}

.gift_form_container {
    grid-row: 1/5;
    background-color: #FFF;
    padding: 24px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    box-shadow: 0px 8px 32px 0px #0000000F;
}

.gift_balance {
    grid-row: 3/4;
    background-color: #FFF;
    padding: 24px;
    border-radius: 8px;
    max-width: 400px;
    box-shadow: 0px 8px 32px 0px #0000000F;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.gift_heading {
    font-family: Barmeno, sans-serif;
    font-size: 24px;
    font-weight: 700;
}

.gifting_info {
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.sender_detail__gift {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.gift_info_detail {
    display: flex;
    justify-content: space-between;
    width: fit-content;
}

.gift_info_detail_key {
    width: 100px;
    font-size: 18px;
    font-weight: 700;
    color: #313131;
}

.gift_info_detail_value {
    font-size: 18px;
    font-weight: 500;
    color: #313131;
}

.choose_giftcard_head {
    font-size: 24px;
    font-weight: 700;
    color: #313131;
}

.giftcard_options_image {
    width: 250px;
    height: 150px;
}

.giftcard_options_label {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    border-radius: 8px;
    box-shadow: 4px 4px 5px 4px #13121214;
    width: 250px;
}

.gift_amount_form {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.gift_amount_options {
    display: flex;
    gap: 24px;
}

.gift_amount_options_label {
    font-size: 16px;
    display: flex;
    align-items: center;
    width: fit-content;
    gap: 8px;
    border-radius: 8px;
}

input.gift_amount_radio {
    border: 1px solid #909090;
    width: 20px;
    height: 20px;
    margin: 0px;
}

input.gift_amount_radio:checked {
    border: 3px solid green;
    color: white;
    accent-color: #2C6F37;
}

.gift-amount-form-field {
    height: 56px;
    width: 100%;
    border-radius: 8px;
    border: 2px solid #313131;
    padding: 0 10px;
    outline: none;
    background-color: white;
    font-weight: 400;
    font-size: 16px;
    box-sizing: border-box;
}

.gift-amount-form-field-error {
    height: 56px;
    width: 100%;
    border-radius: 8px;
    border: 1px solid #F87171;
    padding: 0 10px;
    outline: none;
    background-color: white;
    font-weight: 400;
    font-size: 16px;
    box-sizing: border-box;
}

.error-gift {
    font-size: 12px;
    color: #E42328;
    font-weight: 400;
}

.custom_giftAmount_label {
    font-size: 16px;
    font-weight: 500;
    color: #747474;
}

.custom_giftAmount_label_payment {
    font-size: 16px;
    font-weight: 700;
    color: #313131;
}

.custom_amount_container {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
}

.giftCard_recipient_main_container {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.continue_container {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    gap: 16px
}

.giftcard_continue_button {
    background-color: #E11B22 !important;
    color: #fff !important;
    width: 250px !important;
    height: 56px !important;
    border-radius: 8px !important;
    font-weight: 700 !important;
    font-size: 16px !important;
}
.giftcard_continue_button_disabled {
    background-color: #BFBFBF !important;
    color: #909090 !important;
    width: 250px !important;
    height: 56px !important;
    border-radius: 8px !important;
    font-weight: 700 !important;
    font-size: 16px !important;
}

.giftcard_continue_disabled_button {
    background-color: #BFBFBF !important;
    color: #909090 !important;
    width: 250px !important;
    height: 56px !important;
    border-radius: 8px !important;
    font-weight: 700 !important;
    font-size: 16px !important;
}

.giftcard_back_button {
    background-color: #fff !important;
    color: #E11B22 !important;
    border: 1px solid #E11B22 !important;
    width: 250px !important;
    height: 56px !important;
    border-radius: 8px !important;
    font-weight: 700 !important;
    font-size: 16px !important;
}

.wingos_gift_tnc {
    color: #E11B22 !important;
    text-decoration: underline !important;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
}

.order_summary {
    display: flex;
    flex-direction: column;
    gap: 16px
}

.order_summary_heading {
    font-size: 24px;
    font-weight: 700;
    color: #231F20;
}

.summary_report {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding-right: 12px;
}

.summary_detail {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    font-weight: 700;
}

.card_details {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.payment_button_container {
    display: flex;
    gap: 40px;
}

.table_cell_giftcard_history {
    font-family: Barmeno, sans-serif !important;
    font-weight: 700 !important;
    color: #313131;
    font-size: 16px !important;
    border-bottom: 1px solid #D4D4D4 !important;
    background-color: #fff !important;
}

#headcell1 {
    padding: 8px 31px;
}

#headcell2 {
    padding: 8px 101.5px;
}

#headcell3 {
    padding: 8px 23.5px;
}

#headcell4 #headcell5 {
    padding: 8px 25px;
}

table tr:last-child td:first-child {
    border-bottom-left-radius: 10px;
}

table tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
}

table tr:first-child th:first-child {
    border-top-left-radius: 10px;
}

table tr:first-child th:last-child {
    border-top-right-radius: 10px;
}

.giftCardHistoryPanel {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.gift_card_panel {
    padding: 40px 40px 40px 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
    min-width: 500px;
    gap: 32px;
}

.giftCardHistoryHeading {
    font-family: Barmeno, sans-serif;
    font-size: 24px;
    font-weight: 500;
    color: #1D1D1D
}

.giftCardHistoryButton {
    border-color: transparent;
    background-color: transparent;
    color: #e11b22;
    font-weight: 700 !important;
    font-size: 18px !important;
    cursor: pointer;
}

.pagination_container {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    gap: 8px;
    color: #747474;
    font-size: 10px;
    font-weight: 500;
}

.pageNumberContainer {
    display: flex;
    flex-direction: row;
    gap: 8px;
    color: #1D1D1D;
    font-family: Barmeno, sans-serif;
}

.MuiToolbar-root.MuiToolbar-regular.MuiTablePagination-toolbar.MuiToolbar-gutters {
    display: flex;
    flex-direction: column;
}

.activity_detail_label {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    font-family: Barmeno, sans-serif;
    color: #1D1D1D;
    font-weight: 700;
    font-size: 18px;
}

.heading_giftcard_popup {
    padding: 12px 24px;
    border-bottom: 4px solid #d4d4d4;
    font-size: 24px;
    font-family: Barmeno, sans-serif;
    font-weight: 500;
    color: #1D1D1D
}

.activity_detail_giftcard {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 16px 12px;
}

.close_icon_giftcard_popup {
    display: flex;
    gap: 8px;
    padding: 8px 8px;
    justify-content: flex-end;
}

.activity_detail_data {
    color: #747474;
    font-family: Barmeno, sans-serif;
    font-weight: 700;
    font-size: 16px;
}

.transaction_detail_header{
    font-weight: 700;
    font-family: Barmeno, sans-serif;
    font-size: 20px;
    color: #231F20;
    padding: 12px 24px;
}

.activity_detail_giftcard_trans{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 8px 12px;
}
@media screen and (min-width:1451px) {
    .choose_giftcard_options {
        display: flex;
        justify-content: space-between;
        gap: 16px;
    }
}

@media screen and (max-width:1250px) {
    .senders-details {
        display: flex;
        flex-flow: column;
        gap: 16px;
    }
}

@media screen and (min-width:1250px) {
    .senders-details {
        display: flex;
        flex-flow: row;
        gap: 16px;
    }
}

@media screen and (min-width:1050px) and (max-width:1450px) {
    .choose_giftcard_options {
        display: grid;
    grid-column-gap: 16px;
    grid-auto-flow: column;
    overflow: auto;
    }
}

@media screen and (min-width:350px) and (max-width:1049px) {
    .choose_giftcard_options {
        display: flex;
        flex-direction: row;
        overflow-x: auto ;
        gap: 16px;

    }
    .gift_container {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
    .gift_balance{
        background-color: #FFF;
        padding: 24px;
        border-radius: 8px;
        max-width: 100% !important;
        box-shadow: 0px 8px 32px 0px #0000000F;
        display: flex;
        flex-direction: column;
        grid-gap: 24px;
        gap: 24px;
    }
    .gift_detail{
        max-width: 100% !important;
    }
}

@media screen and (max-width:960px) {
    .gift_amount_options {
        display: flex;
        flex-direction: column;
        gap: 24px;

    }
}
@media screen and (min-width:1050px) and (max-width:1070px) {
    .choose_giftcard {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 32vw;
        min-width: 250px;
    }
}
@media screen and (min-width:1071px) and (max-width:1150px) {
    .choose_giftcard {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 36vw;
        min-width: 250px;
    }
}
@media screen and (min-width:1151px) and (max-width:1250px) {
    .choose_giftcard {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 41vw;
        min-width: 250px;
    }
}

@media screen and (min-width:1251px) and (max-width:1360px) {
    .choose_giftcard {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 46vw;
        min-width: 250px;
    }
}
@media screen and (min-width:1361px) and (max-width:1450px) {
    .choose_giftcard {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 50vw;
        min-width: 250px;
    }
}
.loader .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded{
    align-items: center;
    box-shadow: 0px 0px 0px 0px transparent,0px 0px 0px 0px transparent,0px 0px 0px 0px transparent;
    background-color: transparent;
}

@media screen and (min-width:350px) and (max-width:490px) {
    .gift_container{
        padding: 40px !important;
    }
}