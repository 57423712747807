.staff_schedule__wrapper {
  container: staff-schedule / inline-size;

  padding: 10px 16px 24px 16px;
  height: 100%;

  display: flex;
  flex-direction: column;
  gap: 16px;

  background-color: #FFFFFF;
  border-radius: 8px;
}

.staff_schedule__toolbar {
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
}

.staff_schedule__toolbar__errors {
  padding: 2px 8px;
  display: flex;
  align-items: center;
  gap: 4px;
  background-color: #FEE2E2;
  border-left: 4px solid #E42328;
}

.staff_schedule__toolbar__store_hours {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
}

.staff_schedule__toolbar__item {
  min-width: fit-content;
  display: flex;
  align-items: center;
  gap: 4px;
}

.staff_schedule__toolbar__item__circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.staff_schedule__toolbar__item__label {
  font-size: 14px;
  font-weight: 500;
  color: #1D1D1D;
}

.staff_schedule__scheduler {
  height: 100%;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  gap: 32px;
}

.staff_schedule__staff_list__schedule {
  padding: 16px;

  display: flex;
  flex-direction: column;
  gap: 24px;

  background: #FFFFFF;
  font-weight: 700;
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px #00000014;

  overflow-y: auto;
  cursor: pointer;
}

.staff_schedule__staff_list__schedule[data-selected="true"] {
  background: #FCE8E9;
  color: #CD191F;
}

.staff_schedule__staff_list {
  padding: 16px 8px;

  display: flex;
  flex-direction: column;
  gap: 24px;

  background: #FFFFFF;
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px #00000014;

  overflow-y: auto;
}

.staff_schedule__staff_list__option {
  padding: 10px 16px;
  height: 36px;

  display: flex;
  align-items: center;
  gap: 8px;

  border-radius: 8px;
  cursor: pointer;
}

.staff_schedule__staff_list__option.selected {
  background-color: #BED2C1;
}

.staff_schedule__staff_list__option__index {
  width: 24px;
  height: 24px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #BED2C1;
  color: #1D1D1D;
  font-size: 12px;
  font-weight: 700;
  border-radius: 50%;
}

.staff_schedule__staff_list__option.selected > .staff_schedule__staff_list__option__index {
  background-color: #FFFFFF;
}

.staff_schedule__staff_list__option__label {
  font-size: 14px;
  font-weight: 700;
  color: #1D1D1D;
}

@container staff-schedule (width > 1024px) {
  .staff_schedule__scheduler {
    grid-template-columns: 1fr 3fr;
  }
}
