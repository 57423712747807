.add_shift__wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.add_shift__header {
  padding: 24px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #D4D4D4;
}

.add_shift__header__title {
  font-size: 24px;
  font-weight: 700;
  color: #1D1D1D;
}

.add_shift__form {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 24px;
}

.add_shift__asterisk {
  color: #E11B22;
}

.add_shift__label {
  font-size: 14px !important;
  font-weight: 700 !important;
  font-family: "Barmeno", sans-serif !important;
  color: #747474 !important;
}

.add_shift__shift_timing {
  grid-column: 1/3;
  grid-row: 2/2;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.add_shift__shift_timing__title {
  font-size: 16px !important;
  font-weight: 700 !important;
  color: #1D1D1D !important;
  font-family: "Barmeno", sans-serif !important;
}

.add_shift__shift_timing__grid {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}

.add_shift__helper_text {
  margin: 2px !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  color: #E11B22 !important;
  font-family: "Barmeno", sans-serif !important;
}

.add_shift__response {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.add_shift__success {
  padding: 8px 16px;
  background-color: #e9ffe0;
  color: #008d07;
  font-weight: 700;
  border-left: 4px solid #008d07;
  border-radius: 8px;
}

.add_shift__error {
  margin: 0;
  padding: 8px 16px;
  background-color: #FCE8E9;
  color: #E11B22;
  font-weight: 700;
  border-left: 4px solid #E11B22;
  border-radius: 8px;
  list-style-type: none;
  list-style-position: inside;
}

.add_shift__buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}
