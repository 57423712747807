  @font-face {
    font-family: 'Barmeno';
    src: url('../fonts/Barmeno-Regular.otf') format('opentype');
  }

@media screen and (min-width:300px) {
    .container {
        display: flex;
        flex-direction: column-reverse;
        height: auto;
        width: 96%;
        margin: 10px 10px;
        border-radius: 32px;
        box-shadow: none;
        background-color: #FFFFFF;
        align-items: center;
        font-family:'Barmeno', sans-serif !important ;
    }
  
    .heading {
      width: 87%;
      max-width: none;
      flex: 1;
      padding: 3%;
    }

    .headingTextT {
        font-size: 30px !important;
        width: auto;
        height: auto;
        font-family: 'Barmeno', sans-serif !important;
        font-weight: 700 !important;
        color: #313131;
        margin-top: 16px !important;
      }
  
    .headingInfoT {
        line-height: 1 !important;
        font-size: 18px !important;
        color: rgb(100, 116, 139);
        font-family: 'Barmeno', sans-serif !important;
        margin-top: 25px !important;
        font-weight: 500 !important;
    }

    .MuiFormGroup-root .MuiFormGroup-row .MuiTypography-body1 {
        font-size: 16px !important;
        font-family: 'Barmeno', sans-serif !important;
        font-weight: 500 !important;
        line-height: 1 !important;
      }
      .bgContainer{
        height: 500px !important;
        overflow: hidden;
        width: 100% !important;
    }

    .bgImage{
        height: 111%;
        width: 100%;
        border-radius: 32px 32px 0px 0px;
    }

    .passwordTextT {
        color: rgb(100, 116, 139);
        font-size: 16px !important;
        font-weight: 500 !important;
        font-family: 'Barmeno', sans-serif !important;
        line-height: 1.5 !important;  
    }

    .emailInputT{
        font-size: 16px !important;
        height: 56px !important;
        width: 100%;
    }
  
    .passwordInputT {
        font-size: 16px !important;
        width: 100%;
    }

    .passwordInputNotMatched {
        font-size: 12px !important;
        border: 1px solid #E42328;
        width: 100%;
    }

    .infoBox{
       height: 100px;
        width: 300px;
        margin: 16px 0px;
    }

    .infoTextPoints{
        font-size: 14px !important;
        line-height: 22px !important;
        font-weight: 500 !important;
        font-family: 'Barmeno', sans-serif !important;
        color: rgb(35, 31, 32);
        display: flex;
    }

    .successfulMessage{
        position: absolute;
        font-size: 16px !important;
        line-height: 22px !important;
        font-weight: 500 !important;
        font-family: 'Barmeno', sans-serif !important;
        color: rgb(35, 31, 32);
        display: flex !important;
        margin: 10px !important;
    }

    .button{
        border-radius: 8px !important;
        padding: 16px 0px !important;
        font-size: 18 !important;
        font-weight: 700 !important;
        font-family: 'Barmeno', sans-serif !important;
        width: 100% !important;
        height: 54px;
        max-height: 54px;
        margin: 36px 0px 36px 0px !important;
    }

    .emailNotValid{
        color: rgb(228, 35, 40);
        font-size: 12px !important;
        font-weight: 500 !important;
        font-family: 'Barmeno', sans-serif !important;
        margin-top: 1px !important;
        position: absolute;
    }
    .resetButton{
        border-radius: 8px !important;
        padding: 16px 0px !important;
        font-size: 18px !important;
        font-weight: 700 !important;
        font-family: 'Barmeno', sans-serif !important;
        width: 100% !important;
        height: 54px;
        max-height: 54px;
        margin: 36px 0px 36px 0px !important;
        background-color: #E11B23 !important;
    }
    .container .CustomMobileBox-root-30{
        margin-top: 12px !important;
        height: 56px !important;
    }
    .container .MuiFormLabel-root{
        font-size: 16px !important;
    }

    .container .MuiInputBase-root{
        font-family: 'Barmeno', sans-serif !important;
        font-size: 16px !important;
        font-weight: 500 !important;
        height: 56px !important;
    }

    .mobileBox .MuiFormControl-root-34 {
        height: 56px !important;
      }
      .mobileBox .MuiTextField-root-90 {
        border: none !important;
        margin-top: 32px;
      }

      .choice .MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
        font-family: 'Barmeno', sans-serif !important;
        font-weight: 500;
        font-size: 16px;
      }

      .eyeIcon{
        margin: 0px 0px 0px -50px !important;
      }

 }

@media (min-width: 800px) {
    .container {
        display: flex;
        flex-direction: row;
        border-radius: 32px;
        box-shadow: " 0px 4px 4px rgba(0, 0, 0, 0.25)";
        height: 95%;
        width: 95%;
        margin-left: 2.5%;
        margin-top: 2.5%;
        background-color: #FFFFFF;
        font-family: 'Barmeno', sans-serif !important;
        flex: start;
    }
  
    .heading {
        width: 100%;
        flex: 1 0;
        padding: 4% 2% 3% 4%;
        height: 90%;
    }
  
    .headingTextT {
        font-size: 30px !important;
        width: auto;
        height: auto;
        font-family: 'Barmeno', sans-serif !important;
        font-weight: 700 !important;
        color: #313131;
        margin-top: 16px !important;
      }
  
    .headingInfoT {
        line-height: 1 !important;
        font-size: 18px !important;
        color: rgb(100, 116, 139);
        font-family: 'Barmeno', sans-serif !important;
        margin-top: 25px !important;
        font-weight: 500 !important;
    }

    .MuiFormGroup-root .MuiFormGroup-row .MuiTypography-body1 {
        font-size: 16px !important;
        font-family: 'Barmeno', sans-serif !important;
        font-weight: 500 !important;
        line-height: 1 !important;
      }

    .bgContainer{
        width: 50% !important;
        height: 100% !important;
    }  
    .bgImage{
        height: 100%;
        width: 100%;
        border-radius: 0px 32px 32px 0px;
    }
    .passwordTextT {
        color: rgb(100, 116, 139);
        font-size: 16px !important;
        font-weight: 500 !important;
        font-family: 'Barmeno', sans-serif !important;
        line-height: 1.5 !important;  
    }

    .emailInputT{
        font-size: 16px !important;
        height: 56px !important;
        width: 100%;
    }
  
    .passwordInputT {
        font-size: 16px !important;
        width: 100%;
    }

    .passwordInputNotMatched {
        font-size: 12px !important;
        border: 1px solid #E42328;
        width: 100%;
    }

    .infoBox{
       height: 100px;
        width: 300px;
        margin: 16px 0px;
    }

    .infoTextPoints{
        font-size: 14px !important;
        line-height: 22px !important;
        font-weight: 500 !important;
        font-family: 'Barmeno', sans-serif !important;
        color: rgb(35, 31, 32);
        display: flex;
    }

    .successfulMessage{
        position: absolute;
        font-size: 16px !important;
        line-height: 22px !important;
        font-weight: 500 !important;
        font-family: 'Barmeno', sans-serif !important;
        color: rgb(35, 31, 32);
        display: flex !important;
        margin: 10px !important;
    }

    .button{
        border-radius: 8px !important;
        padding: 16px 0px !important;
        font-size: 18 !important;
        font-weight: 700 !important;
        font-family: 'Barmeno', sans-serif !important;
        width: 100% !important;
        height: 54px;
        max-height: 54px;
        margin: 36px 0px 36px 0px !important;
    }

    .emailNotValid{
        color: rgb(228, 35, 40);
        font-size: 12px !important;
        font-weight: 500 !important;
        font-family: 'Barmeno', sans-serif !important;
        margin-top: 1px !important;
        position: absolute;
    }
    .resetButton{
        border-radius: 8px !important;
        padding: 16px 0px !important;
        font-size: 18px !important;
        font-weight: 700 !important;
        font-family: 'Barmeno', sans-serif !important;
        width: 100% !important;
        height: 54px;
        max-height: 54px;
        margin: 36px 0px 36px 0px !important;
        background-color: #E11B23 !important;
    }
    .container .CustomMobileBox-root-30{
        margin-top: 12px !important;
        height: 56px !important;
    }
    .container .MuiFormLabel-root{
        font-size: 16px !important;
    }

    .container .MuiInputBase-root{
        font-family: 'Barmeno', sans-serif !important;
        font-size: 16px !important;
        font-weight: 500 !important;
        height: 56px !important;
    }

    .mobileBox .MuiFormControl-root-34 {
        height: 56px !important;
      }
      .mobileBox .MuiTextField-root-90 {
        border: none !important;
        margin-top: 32px;
      }

      .choice .MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
        font-family: 'Barmeno', sans-serif !important;
        font-weight: 500;
        font-size: 16px;
      }

      .eyeIcon{
        margin: 0px 0px 0px -50px !important;
      }
}