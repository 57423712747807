.unsubscribe_success {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    margin: 20px 0;
}

.sucess_text {
    font-family: "Barmeno", sans-serif !important;
    font-size: 24px !important;
    font-weight: 700 !important;
    line-height: 28.8px !important;
    color: rgba(0, 0, 0, 1) !important;
}

.navigate {
    border-radius: 8px;
    background-color: rgba(225, 27, 34, 1) !important;
    color: rgba(255, 255, 255, 1) !important;
    font-family: "Barmeno", sans-serif !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 19.2px !important;
    padding: 18px 50px !important;
    height: 56px !important;
    width: 472px !important;
}