.error_popup__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
}

.error_popup__wrapper > img {
  height: 256px;
  aspect-ratio: 1;
  object-fit: contain;
}

.error_popup__wrapper > p {
  font-size: 24px;
  font-weight: 700;
}

.error_popup__messages {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.error_popup__messages > h2 {
  margin: 0;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
}

.error_popup__messages > ul {
  margin: 0;
  padding: 0 8px;
  max-height: 196px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  list-style-position: inside;
  list-style-type: circle;
  overflow-y: auto;
}

.error_popup__messages > ul > li {
  padding: 6px 12px;
  background-color: rgb(241, 241, 241);
  border-radius: 8px;
}
