.alert_msg {
    font-family: "Barmeno, sans-Serif" !important;
    font-weight: 400 !important; 
    font-size: 16px !important; 
    line-height: 16px !important;
    color: rgba(49, 49, 49, 1);
}

#root-element-container {
  position: fixed;
  bottom: 13px;
  right: 40px;
  height: 56px;
  width: auto;
}

.snackbar {
  background-color: #333;
  color: #fff;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.close {
  position: absolute;
  top: 5px;
  right: 10px;
  cursor: pointer;
}
.MuiButtonBase-root.MuiButton-root.MuiButton-text {
  font-family: Barmeno;
  font-size: 16px;
  font-weight: 700;
}