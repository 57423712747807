.additional_address_head {
    padding: 0px 0px 16px 0px;
    border-bottom: 1px solid #D4D4D4;
    font-size: 20px;
    font-weight: 500;
    color: #231F20;
    line-height: 24px;
    font-family: barmeno, sans-serif;
}

.address_start {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.address_type {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
    color: #313131;
}

.address_info {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.address_field {
    color: #313131;
    font-weight: 500;
    font-size: 16px;
}

.form_grid_container {
    display: grid;
    grid-template-areas:
        'name phone'
        'address address'
        'city postal_code';
    gap: 16px;
    padding: 10px;
}

#name {
    grid-area: name;
}

#phone {
    grid-area: phone;
}

#address {
    grid-area: address;
}

#city {
    grid-area: city;
}

#postal_code {
    grid-area: postal_code;
}

.address_type_radio {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    padding: 4px 12px;
    border-radius: 8px;
    background: white;
    border: 1px solid #D4D4D4;
    height: 34px;
    width: 74px;
    justify-content: center;
    color: #313131;
    font-size: 16px;
}

.address_type_radio_selected {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    padding: 4px 12px;
    border-radius: 8px;
    background: linear-gradient(99.09deg, #F6B8BB 2.64%, #E11B23 100%);
    color: white;
    border: 0px;
    height: 34px;
    width: 74px;
    justify-content: center;
    font-size: 16px;
}

.default_address_container input[type="checkbox"]:checked {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Crect width='24' height='24' rx='6' fill='%232C6F37'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M18.0718 6.50666C18.6204 6.87856 18.7636 7.62473 18.3917 8.1733L12.2902 17.1733C12.091 17.4672 11.7718 17.6578 11.4185 17.6938C11.0652 17.7297 10.7142 17.6074 10.4598 17.3596L5.76124 12.7841C5.28644 12.3218 5.27636 11.562 5.73873 11.0872C6.2011 10.6124 6.96083 10.6024 7.43564 11.0647L11.1077 14.6406L16.4052 6.82655C16.7771 6.27799 17.5232 6.13477 18.0718 6.50666Z' fill='white'/%3E%3C/svg%3E");
    width: 20px;
    height: 20px;
    border: 0px;
  }
  .default_address_container input[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #909090;
    border-radius: 6px;
    margin-right: 8px;
}
.default_address_container{
    display: flex;
align-items: center;
padding: 0px 10px;
font-family: Barmeno, sans-serif;
font-weight: 700;
color: #313131;
}

#not-fav-clicked {
    background-color: transparent !important;
    color: #313131 !important;
  }
  
.details_field__wrapper .react-tel-input {
    margin: 0px !important;
  }

  .phone-input-container.react-tel-input {
    margin-top: 0px !important;
  }