.flag_area_success__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  text-align: center;
}

.flag_area_success__wrapper > img {
  height: 256px;
  aspect-ratio: 1;
  object-fit: contain;
}

.flag_area_success__wrapper > p {
  margin: 0;
  font-size: 24px;
  font-weight: 700;
}
