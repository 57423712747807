.toolbar {
    margin: 25px 40px;
    padding: 0px !important;
  }

.grid_items a {
    margin: 0px !important;
    padding: 8px 6px !important;
    font-family: Barmeno, Sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    text-transform: uppercase;
    color: #313131 !important;
}

.img_link {
    margin: 0px 16px 0px 0px !important;
    padding: 0 !important;
    cursor: pointer;
}

button {
    margin: 0px !important;
    padding: 8px 6px !important;
    font-family: Barmeno, Sans-serif !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 16px !important;
    text-transform: uppercase;
}

.profile_icon {
    padding: 0px !important;
    border: none !important;
    background-color: transparent !important;
}

.signInBtn {
    padding: 0px !important;
}

.signInBtn button {
    padding: 0px !important;
}

.order_btn {
    font-weight: 500 !important;
}

.icon_img {
    width: 48px !important;
    height: 48px !important;
    border: none !important;
    background-color: transparent !important;
}

.menu_icon {
    display: none !important;
    padding: 0px !important;
    background-color: transparent !important;
    border: none !important;
}

.signInBtn .MuiSvgIcon-root {
    color: #313131 !important;
    width: 20px !important;
    height: 20px !important;
}
.dropdown-button{
    font-size: 14px !important;
}
.MuiTypography-root.MuiLink-root.MuiLink-underlineNone.red-heading.MuiTypography-colorPrimary{
    color: #E11B22 !important;
}

.MuiButtonBase-root.MuiButton-root.MuiButton-text.red-heading {
    color: #E11B22 !important;
}

#simple-menu .MuiPaper-root.MuiPaper-rounded {
    top: 115px !important;
    width: 310px;
    border-radius: 8px !important;
}

#simple-menu .MuiPaper-root.MuiPaper-rounded .MuiList-root.MuiMenu-list.MuiList-padding {
    padding: 0 !important;
}

#simple-menu .MuiPaper-root.MuiPaper-rounded .MuiList-root.MuiMenu-list.MuiList-padding .MuiListItem-root {
    color: #313131;
    font-weight: 400;
    font-size: 14px;
}

#simple-menu .MuiPaper-root.MuiPaper-rounded .MuiList-root.MuiMenu-list.MuiList-padding .MuiListItem-root.MuiListItem-button:hover {
    color: #747474 !important;
    font-size: 14px !important;
    font-weight: 400;
    background-color: #ccd6ce;
} 

.menu_item {
    color: #747474 !important;
    font-size: 16px !important;
    font-weight: 400;
    background-color: #9EBDA3 !important;
}

#rewardBtn .MuiButton-label {
    height: auto !important;
    font-size: 16px;
    line-height: 16px;
    font-weight: 600;
}

#simple-menu .MuiPaper-root.MuiPaper-rounded {
    top: 115px !important;
    width: 310px;
}

#simple-menu .MuiPaper-root.MuiPaper-rounded .MuiList-root.MuiMenu-list.MuiList-padding {
    padding: 0 !important;
}

#simple-menu .MuiPaper-root.MuiPaper-rounded .MuiList-root.MuiMenu-list.MuiList-padding .MuiListItem-root {
    color: #313131;
    font-weight: 400;
    font-size: 14px;
}

.menu_item {
    color: #747474 !important;
    font-size: 14px !important;
    font-weight: 400;
    background-color: #9EBDA3 !important;
}

/* Media query for screens up to 744px width */
@media (max-width: 744px) {
    .grid_items > *:not(:first-child) {
        display: none;
      }
    .icon_img {
        display: none !important;
    }
    .menu_icon {
        display: block !important;
    }
}

/* Media query for screens from 745px to 1279px width */
@media (min-width: 745px) and (max-width: 1024px) {
    .grid_items > *:not(:first-child) {
        display: none; /* Hide all child elements except the first one */
      }
    .icon_img {
        display: none !important;
    }
    .menu_icon {
        display: block !important;
    }
}

/* Media query for screens from 745px to 1279px width */
@media (min-width: 1024px) and (max-width: 1279px) {
    .grid_items > *:not(:first-child) {
        display: none; /* Hide all child elements except the first one */
      }
 
    .menu_icon {
        display: block !important;
    }
}

/* Media query for screens from 1280px to 1479px width */
@media (min-width: 1280px) and (max-width: 1479px) {

    .grid_items {
        gap: 8px !important;
    }

    .secondGridItem {
        gap: 8px !important;
    }

    .menu_icon {
        display: none !important;
    }
}

/* Media query for screens from 1280px to 1919px width */
@media (min-width: 1480px) and (max-width: 1919px) { 

    .menu_icon {
        display: none !important;
    }
}