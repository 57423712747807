@media screen and (min-width:1280px) {
    .password_field__wrapper {
        display: flex;
        flex-direction: column;
      }
    .eyeIcon-button{
        margin: 0px 0px 0px -50px !important;
    }

}


@media screen and (min-width:300px) and (max-width: 1279px) {
    .password_field__wrapper {
        display: flex;
        flex-direction: column;
      }
      .eyeIcon-button{
        margin: 0px 0px 0px -50px !important;
    }
}

