.created_staff__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.created_staff__title {
  font-size: 24px;
  font-weight: 700;
  color: #1D1D1D;
}
