.manage_note__wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
  
    font-family: "Barmeno", sans-serif;
  }
  
  .manage_note__header {
    padding: 24px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #D4D4D4;
  }
  
  .manage_note__header .manage_note__title {
    font-size: 24px;
    font-weight: 700;
  }
  
  .manage_note__content {
    padding: 24px 16px;
  }
  
  .manage_note__label {
    font-size: 16px !important;
    font-weight: 700 !important;
    font-family: "Barmeno", sans-serif !important;
  }
  
  .manage_note__textfield {
    border: 1px solid #BFBFBF !important;
    border-radius: 8px !important;
  }
  
  .manage_note__input {
    font-family: "Barmeno", sans-serif !important;
  }
  
  .manage_note__helper_text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    color: #525252 !important;
    font-size: 12px !important;
    font-family: "Barmeno", sans-serif !important;
    text-align: right !important;
  }
  
  .manage_note__helper_text__error {
    color: #E42328 !important;
  }
  
  .manage_note__footer {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  
  .note_success__wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }
  
  .note_success__text {
    font-size: 24px;
    font-weight: 700;
    font-family: "Barmeno", sans-serif;
  }

  /* .Modal {
    position: relative;
} */


/* .modal-content {
  position: relative; 
} */
  .notes_success_cross {
    
    margin-right: 5px;
    
  }