.staff_info__wrapper {
  container: staff-info-wrapper / inline-size;

  display: flex;
  flex-direction: column;
  gap: 24px;
  height: auto;
}

.staff_info__unauthorised__wrapper {
  padding: 24px;
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;

  background-color: #fff;
  border-radius: 8px;
}

.staff_info__unauthorised__image {
  height: auto;
  width: 100%;
  max-width: 520px;
}

.staff_info__unauthorised__text {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  color: #1D1D1D;
}

.staff_info__topbar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  grid-gap: 24px;
  column-gap: 24px;
  row-gap: 8px;
}

@container staff-info-wrapper (width > 640px) {
  .staff_info__topbar {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.staff_info__topbar__right {
  display: flex;
  align-items: center;
  gap: 12px;
}

.staffInformation__tab__wrapper {
  padding: 2px !important;
  border: 1px solid #1D1D1D;
  border-radius: 50px;
}

.staffInformation__tab__hidden_indicator {
  display: none;
}

.staffInformation__tab {
  padding: 10px 12px !important;
  min-width: 148px !important;
  color: #909090 !important;
  text-transform: capitalize !important;
}

.staffInformation__tab__selected {
  padding: 10px 12px !important;
  min-width: 148px !important;
  background-color: #1D1D1D !important;
  color: #FFFFFF !important;
  text-transform: capitalize !important;
  border-radius: 50px !important;
}

.staff_info__select__menu {
  margin-top: 8px;
  border-radius: 8px !important;
  font-family: "Barmeno", sans-serif !important;
}

.staff_info__select__list {
  padding: 0px !important;
}

.staff_info__select {
  min-width: 256px;
  height: 44px;
  background-color: #fff !important;
  border-radius: 8px !important;
  font-family: inherit !important;
}

.staff_info__select__input {
  padding: 0px 12px !important;

  display: flex !important;
  align-items: center;
  gap: 8px;

  font-family: "Barmeno", sans-serif !important;
}

.staff_info__select__item {
  font-family: "Barmeno", sans-serif !important;
}

.staff_info__select__item.selected {
  background-color: #2C6F37 !important;
  color: #fff !important;
}

.staff_info__add__button {
  padding: 10px 16px !important;
  display: flex;
  align-items: center;
  min-width: 124px;
  height: 44px;
  background-color: #E11B22 !important;
  border-radius: 8px !important;
}

.staff_info__add__button__text {
  font-family: "Barmeno", sans-serif !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #fff !important;
}

.staff_information__content {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.search__wrapper {
  padding: 0px 12px;

  display: flex;
  align-items: center;
  column-gap: 8px;

  background-color: #fff;
  font-family: "Barmeno", sans-serif;
  color: #DADADA;
  border-radius: 8px;

  border: 1px solid #BFBFBF;
  cursor: text;
}

.staff_info__add__button__disabled {
  background-color: #BFBFBF !important;
  color: #909090 !important;
}

.weekly_notes__wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.weekly_notes__header {
  padding: 24px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  border-bottom: 1px solid #D4D4D4;
}

.weekly_notes__header > h1 {
  margin: 0;
  font-size: 24px;
  font-weight: 700;
}

.weekly_notes__topbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}

.weekly_notes__current_date {
  display: flex;
  align-items: center;
  gap: 8px;
}

.weekly_notes__add_note__section {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.weekly_notes__add_note__button_group {
  padding: 0 24px;
  display: flex;
  gap: 24px;
}

.weekly_notes__add_note__add_button {
  gap: 8px;
  color: #E11B22 !important;
}

.weekly_notes__add_note__helper_text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.weekly_notes__add_note__helper_text[data-error="true"] {
  color: #E11B22 !important;
}

.weekly_notes__body {
  max-height: 256px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow-y: auto;
}

.weekly_notes__note {
  padding: 4 0 4 12;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  border-left: 2px solid #A2A2A2;
  border-radius: 2px;
}

.weekly_notes__note > p {
  margin: 0;
}

span.weeklt_notes__created_at {
  color: #A2A2A2;
}

.weekly_notes__empty {
  padding: 16px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.weekly_notes__empty > p {
  margin: 0;
  font-size: 16px;
  font-weight: 700;
  color: #909090;
}

.weekly_notes__note__right {
  display: flex;
  align-items: center;
  gap: 16px;
}
