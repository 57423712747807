.time_off__button {
  min-width: 96px !important;
  padding: 10px 16px !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
  font-weight: 400 !important;
}

.time_off__wrapper {
  container: time-off / inline-size;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.time_off__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  border-bottom: 1px solid #D4D4D4;
}

.time_off__body {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.time_off__date_inputs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}

.time_off__footer {
  display: flex;
  align-items: center;
  gap: 16px;
}

.time_off__helper_text {
  margin: 0 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.time_off__helper_text[data-error="true"] {
  color: #E11B22;
}

@container time-off (width < 500px) {
  .time_off__date_inputs {
    flex-wrap: wrap;
  }
}
