.chip__root {
    display: inline-flex !important;
    align-items: center !important;
    gap: 4px !important;
    padding: 2px 6px !important;
    height: 24px !important;
    border-radius: 16px !important;
}

.chip__icon {
    margin: 0 !important;
    color: transparent !important;
}

.chip__label {
    padding: 0 !important;
    font-size: 14px !important;
    font-weight: 700 !important;
}