@font-face {
    font-family: 'Verona';
    src: url('./VeronaScript.ttf') format('truetype');
}

.subscription_form {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 118px;
}

.subscribe_error {
    position: absolute;
    font-family: "Barmeno", sans-serif;   
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    color: #E11B22;
    bottom: 0;
}

.footer_social_icons {
    margin: 16px 0 0 0;
    display: flex;
}

.footer_social_icons img {
    width: 24px;
    height: 24px;
}

@media screen and (min-width:1280px) {
    .mainDiv {
        max-width: 100%;
        margin: 0 auto;
        height: 400px;
        grid-template-rows: auto;
        display: grid;
        align-items: center;
        padding: 20px 0px 20px 0px;
        gap: 40px;
        background-color: #231F20;
        color: #ffffff;
    }

    .contactDiv {
        width: 100%;
        min-width: 160px;
    }

    .firstDiv {
        display: grid;
        padding: 24px 40px 0px 24px;
        grid-template-columns: 69% 25%;
        grid-gap: 80px;
    }

    .footerLeftDiv {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    .logoStyle {
        width: 151.75px;
        height: 80px;
    }

    .logoPara {
        font-family: 'Barmeno' sans-serif;
        font-weight: 600;
        font-size: 16px;
        line-height: 19.2px;
    }

    .contactBlocks {
        width: 100%;
        display: grid;
        grid-template-columns: 36% 1fr;
        grid-gap: 40px;
    }

    .headings {
        font-family: 'Verona' !important;
        font-style: normal;
        font-weight: 400 !important;
        font-size: 36px !important;
    }

    .secondDiv {
        margin: -15px auto;
        width: 100%;
        display: flex;
        border-top: 0.5px solid #D4D4D4;
        padding: 24px 0px;
        justify-content: center;
    }

    .footerList {
        margin-top: 24px;
    }

    .listItems {
        font-family: 'Barmeno' sans-serif !important;
        font-size: 16px !important;
        padding: 8px 6px;
        grid-gap: 8px;
        gap: 8px;
        font-weight: 700 !important;
        cursor: pointer;
    }

    ;

    .infoDiv {
        width: 85%;
    }

    .InsideFirstDiv {
        display: grid;
        grid-template-columns: 50% 50%;
        gap: 16%;
    }

    .infoHeading {
        width: 100%;
        font-family: 'Barmeno' sans-serif !important;
        font-weight: 700 !important;
        font-size: 18px !important;
    }

    .infoPara {
        font-family: 'Barmeno' sans-serif !important;
        font-weight: 500 !important;
        font-size: 16px !important;
        line-height: 24px !important;
        margin-top: 8px !important;
    }

    .emailInput {
        width: 100%;
        margin-top: 32px !important;
        height: 56px;
        background-color: #ffffff;
        border-radius: 8px
    }

    .emailPlaceholder {
        color: #94A3B8 !important;
        font-family: 'Barmeno' sans-serif !important;
        font-weight: 500 !important;
        font-size: 16px !important;
        line-height: 24px !important;
    }

    .formBtn {
        width: 250px;
        padding: 10px 32px 10px 32px !important;
        background-color: #E11B23 !important;
        border-radius: 8px !important;
        color: #ffffff !important;
        font-family: 'Barmeno' sans-serif !important;
        font-weight: 600 !important;
        font-size: 16px !important;
        line-height: 19.2px !important;
        height: 56px;
    }

    .footerHr {
        background-color: #D4D4D4;
        height: 0.5px;
        margin: 0.5px 0px
    }

    .secondPara {
        font-family: 'Barmeno' sans-serif;
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        color: #ffffff
    }
}

@media screen and (max-width:1280px) {
    .mainDiv {
        max-width: 100%;
        margin: 0 auto;
        height: 400px;
        grid-template-rows: auto;
        display: grid;
        align-items: center;
        padding: 20px 0px 20px 0px;
        gap: 40px;
        background-color: #231F20;
        color: #ffffff;
    }

    .firstDiv {
        display: grid;
        padding: 24px 40px 0px 24px;
        grid-template-columns: 69% 25%;
        grid-gap: 80px;
    }

    .InsideFirstDiv {
        display: grid;
        grid-template-columns: 50% 50%;
        gap: 12%;
    }

    .footerLeftDiv {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    .logoStyle img {
        width: 100%;
    }

    .logoStyle {
        width: 60px;
    }

    .logoPara {
        font-family: 'Barmeno' sans-serif;
        font-weight: 600;
        font-size: 16px;
        line-height: 19.2px;
    }

    .contactDiv {
        width: 100%;
        min-width: 160px;
    }

    .contactBlocks {
        width: 100%;
        display: grid;
        grid-template-columns: 36% 1fr;
        grid-gap: 40px;
    }

    .headings {
        font-family: 'Verona' !important;
        font-style: normal;
        font-weight: 400 !important;
        font-size: 36px !important;
    }

    .secondDiv {
        margin: -15px auto;
        width: 100%;
        display: flex;
        border-top: 0.5px solid #D4D4D4;
        padding: 24px 0px;
        justify-content: center;
    }

    .footerList {
        margin-top: 24px;
    }

    .listItems {
        font-family: 'Barmeno' sans-serif !important;
        font-size: 16px !important;
        padding: 8px 6px;
        grid-gap: 8px;
        gap: 8px;
        font-weight: 700 !important;
        cursor: pointer;
    }

    ;

    .infoDiv {
        width: 85%;
    }

    .infoHeading {
        width: 100%;
        font-family: 'Barmeno' sans-serif !important;
        font-weight: 700 !important;
        font-size: 18px !important;
    }

    .infoPara {
        font-family: 'Barmeno' sans-serif !important;
        font-weight: 500 !important;
        font-size: 16px !important;
        line-height: 24px !important;
        margin-top: 8px !important;
    }

    .emailInput {
        width: 95%;
        margin-top: 32px !important;
        height: 56px;
        background-color: #ffffff;
        border-radius: 8px
    }

    .emailPlaceholder {
        color: #94A3B8 !important;
        font-family: 'Barmeno' sans-serif !important;
        font-weight: 500 !important;
        font-size: 16px !important;
        line-height: 24px !important;
    }

    .formBtn {
        width: 80%;
        padding: 10px 32px 10px 32px !important;
        background-color: #E11B23 !important;
        border-radius: 8px !important;
        color: #ffffff !important;
        font-family: 'Barmeno' sans-serif !important;
        font-weight: 600 !important;
        font-size: 16px !important;
        line-height: 19.2px !important;
        height: 56px;
    }

    .footerHr {
        background-color: #D4D4D4;
        height: 0.5px;
        margin: 0.5px 0px
    }

    .secondPara {
        font-family: 'Barmeno' sans-serif;
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        color: #ffffff
    }
}

@media screen and (max-width:1023px) {
    .mainDiv {
        max-width: 100%;
        margin: 0 auto;
        height: auto;
        grid-template-rows: auto;
        display: grid;
        align-items: center;
        padding: 20px 0px 20px 0px;
        gap: 40px;
        background-color: #231F20;
        color: #ffffff;
    }

    .firstDiv {
        display: flex;
        padding: 24px 40px 0px 24px;
        gap: 27px;
        flex-direction: column;
    }

    .InsideFirstDiv {
        display: grid;
        grid-template-columns: 43% 45%;
        gap: 50px;
    }

    .footerLeftDiv {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    .logoStyle img {
        width: 100%;
    }

    .logoStyle {
        width: 60px;
    }

    .logoPara {
        font-family: 'Barmeno' sans-serif;
        font-weight: 600;
        font-size: 16px;
        line-height: 19.2px;
    }

    .contactDiv {
        width: 100%;
        min-width: 100%;
    }

    .contactBlocks {
        width: 100%;
        display: grid;
        grid-template-columns: 50% 50%;
        grid-gap: 40px;
        align-self: end;
    }

    .infoDiv {
        width: 100%;
        display: grid;
        grid-template-columns: 48% 38% 15%;
    }

    .headings {
        font-family: 'Verona' !important;
        font-style: normal;
        font-weight: 400 !important;
        font-size: 36px !important;
    }

    .secondDiv {
        margin: -15px auto;
        width: 100%;
        display: flex;
        border-top: 0.5px solid #D4D4D4;
        padding: 24px 0px;
        justify-content: center;
    }

    .footerList {
        margin-top: 2px;
    }

    .listItems {
        font-family: 'Barmeno' sans-serif !important;
        font-size: 16px !important;
        padding: 8px 6px;
        grid-gap: 8px;
        gap: 8px;
        font-weight: 700 !important;
        cursor: pointer;
    }

    .infoHeading {
        width: 100%;
        font-family: 'Barmeno' sans-serif !important;
        font-weight: 700 !important;
        font-size: 18px !important;
    }

    .infoPara {
        font-family: 'Barmeno' sans-serif !important;
        font-weight: 500 !important;
        font-size: 16px !important;
        line-height: 24px !important;
        margin-top: 8px !important;
    }

    .emailInput {
        width: 95%;
        margin-top: 32px !important;
        height: 56px;
        background-color: #ffffff;
        border-radius: 8px
    }

    .emailPlaceholder {
        color: #94A3B8 !important;
        font-family: 'Barmeno' sans-serif !important;
        font-weight: 500 !important;
        font-size: 16px !important;
        line-height: 24px !important;
    }

    .formBtn {
        width: 100%;
        padding: 10px 32px 10px 32px !important;
        background-color: #E11B23 !important;
        border-radius: 8px !important;
        color: #ffffff !important;
        font-family: 'Barmeno' sans-serif !important;
        font-weight: 600 !important;
        font-size: 16px !important;
        line-height: 19.2px !important;
        height: 56px;
        min-width: 40px !important;
    }

    .footerHr {
        background-color: #D4D4D4;
        height: 0.5px;
        margin: 0.5px 0px
    }

    .secondPara {
        font-family: 'Barmeno' sans-serif;
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        color: #ffffff
    }
}

.listItems .MuiTypography-root.MuiLink-root.MuiLink-underlineHover.links.MuiTypography-colorPrimary{
    color: inherit !important;
    text-transform: none !important;
    
}