.phone-input {
  border-radius: 8px !important;
}

.phone-input-button {
  border: 1px solid #1d1d1d !important;
}

.phone-input-dropdown {
  margin: 4px 0 !important;
  padding: 8px 0 !important;
  border-radius: 8px !important;
  overflow: clip;
}

.react-tel-input .flag-dropdown.open {
  z-index: 1000;
}
