.delete_address__wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.delete_address__wrapper > img {
  height: 300px;
  aspect-ratio: 1;
  object-fit: contain;
}

.delete_address__body {
    display: flex;
    flex-direction: column;
    gap: 8px;
    text-align: center;
}

.delete_address__body > h3 {
    margin: 0;
    font-size: 24px;
    font-weight: 700;
}

.delete_address__body > p {
    margin: 0;
    font-size: 16px;
}

.delete_address__button_group {
    display: flex;
    align-items: center;
    gap: 16px;
}
