.filter_btn {
    height: 45px;
    border: 1px solid rgba(225, 27, 34, 1) !important;
    border-radius: 8px !important;
    color: rgba(225, 27, 34, 1) !important;
    text-transform: none !important;
    padding: 8px 16px !important;
    font-size: 24px !important;
    font-weight: 700 !important;
    line-height: 28.8px !important;
    font-family: "Barmeno", sans-serif !important;
}

.favourite-filter {
    display: flex;
    justify-content: space-between;
}

.favourite-filter .head-content {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.favourite-filter .header {
    font-family: "Barmeno", sans-serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.05em;
    color: rgb(49, 49, 49);
}

.favourite-filter .count {
    font-family: "Barmeno", sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: rgb(49, 49, 49);
}

.pad_top {
    padding: 70px 16px 40px 16px !important;
}

.fav-menuContainer {
    display: flex;
    flex-direction: column;
    gap: 36px;
    padding: 32px 40px 116px 0px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    width: 100%;
}

.all-favourite-card-container {
    display: flex;
    flex-wrap: wrap;
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;
    padding: 40px 80px;
    border: 1px solid transparent;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.85);
    row-gap: 84px;
}

.favourites-menu-mainCard {
    max-width: 318px !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    overflow: visible !important;
    padding: 0px 12px 29px 12px ;
    height: 340px;
    width: 100%;
    min-height: 156px;
    box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.1) !important;
}

.fav-card-header {
    margin: auto auto 0 0 !important;
    padding: 16px 16px 0 16px !important;
    font-family: "Barmeno", sans-serif !important;
    font-weight: 700 !important;
    font-size: 18px !important;
    line-height: 21.6px !important;
}

.fav-card-header .MuiCardHeader-action {
    align-self: center;
    margin: auto;
}

.fav-card-header.price {
    padding: 0 16px !important;
    margin: 14px auto 16px 0 !important;
}

.product-card-header {
    display: flex;
    align-items: center;
    gap: 4px;
    margin: 0 16px 16px auto !important;
}

.product-card-header .product_type {
    padding: 0 !important;
    color:rgba(116, 116, 116, 1);
    font-family: "Barmeno", sans-serif  !important;
    font-weight: 500  !important;
    font-size: 14px  !important;
    line-height: 14px  !important;
}

.favorites_add_cart {
    background-color: #E11B22 !important;
    height: 44px;
    width: 90%;
    color: white !important;
}

.favourites_btn.MuiIconButton-root {
    color: #E11B22;
}

.share_btn.MuiIconButton-root {
    color: #313131;
}

.empty-info {
    font-family: "Barmeno", sans-serif !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 23.2px !important;
}

.empty, .add-info {
    font-size: 16px !important;
    font-family: "Barmeno", sans-serif !important;
}

.menu_btn {
    width: 250px;
    height: 56px;
    border-radius: 8px;
    background-color: rgba(225, 27, 34, 1) !important;
    color: rgba(255, 255, 255, 1) !important;
    font-family: "Barmeno", sans-serif !important;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
}