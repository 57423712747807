.card_detail_component{
    display: flex;
    flex-direction: row;
    gap: 24px;
    padding: 12px;
    justify-content: space-between;
    box-shadow: 0px 2px 8px 0px #00000014;
}
.card_detail_container{
    display: flex;
  flex-direction: column;
  gap: 16px;
}
.card_name_expiry{
    display: flex;
  flex-direction: row;
  gap: 16px;
}

.card_name_container{
    display: flex;
  flex-direction: column;
  gap: 8px;
  width: 200px;
  font-weight: 700;
  font-size: 18px;
}

.card_brand_number_container{
    display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  font-size: 16px;
}
.add_new_card_arrow{
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  font-family: Barmeno,sans-serif;
  color: #1d1d1d;
}