
/* General Layout */
.dashboard-wrapper {
    display: flex;
    flex-direction: column;
    padding: 24px;
    background-color: #f8f9fa; /* Optional light background */
  }
  
  .toolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }
  
  .toolbar .search-field {
    margin-right: 16px;
  }
  
  /* Table Container */
  .table-container {
    width: 1192px; /* Fixed width as per Figma */
    max-height: 816px; /* Dynamic height based on content */
    margin-left: 0px; /* Positioning from Figma */
    margin-top: 0px;
    background-color: #ffffff; /* White background */
    border-radius: 8px; /* Rounded corners */
    overflow: auto; /* Scroll for overflow */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional shadow for aesthetics */
  }
  
  /* Table Styles */
  .table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .table thead {
    background-color: #f1f1f1; /* Light gray background for header */
  }
  
  .table thead th {
    font-weight: bold;
    padding: 12px 16px;
    text-align: left;
    border-bottom: 2px solid #e0e0e0; /* Header bottom border */
  }
  
  .table tbody tr {
    border-bottom: 1px solid #e0e0e0; /* Light border between rows */
  }
  
  .table tbody td {
    padding: 12px 16px;
  }
  
  /* Actions in the Table */
  .table .action-icons {
    display: flex;
    gap: 8px; /* Space between action icons */
  }
  
  .table .action-icons button {
    border: none;
    background: none;
    cursor: pointer;
  }
  
  .table .action-icons button.view {
    color: #1976d2; /* Blue color for view */
  }
  
  .table .action-icons button.edit {
    color: #388e3c; /* Green color for edit */
  }
  
  .table .action-icons button.delete {
    color: #d32f2f; /* Red color for delete */
  }
  
  /* Pagination */
  .pagination {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
  }
  
  .pagination button {
    margin: 0 4px;
    padding: 6px 12px;
    border-radius: 16px; /* Rounded pagination buttons */
    border: 1px solid #e0e0e0;
    background-color: #ffffff;
    cursor: pointer;
  }
  
  .pagination button:hover {
    background-color: #1976d2;
    color: #ffffff;
  }
  
  .pagination button.active {
    background-color: #1976d2;
    color: #ffffff;
    border: none;
  }

  .modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    width: 400px;
  }
  
  .customer_information__content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .staff_info__add__button {
    padding: 10px 16px !important;
    display: inline-flex; /* Ensures content stays inline */
    align-items: center;
    justify-content: center; /* Centers the content */
    white-space: nowrap; /* Prevents text from wrapping */
    min-width: auto; /* Allow the button to fit its content */
    height: 44px;
    background-color: #E11B22 !important;
    border-radius: 8px !important;
  }
  
  .staff_info__add__button__text {
    font-family: "Barmeno", sans-serif !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #fff !important;
    text-transform: uppercase; /* Keeps text in uppercase as per design */
    margin-left: 8px; /* Space between icon and text */
    white-space: nowrap; /* Prevents text from wrapping */
  }
  
.staff_info__add__button__disabled {
  background-color: #BFBFBF !important;
  color: #909090 !important;
}

.staff_info__wrapper {
  container: staff-info-wrapper / inline-size;

  display: flex;
  flex-direction: column;
  gap: 24px;
  height: auto;
}

@container staff-info-wrapper (width > 640px) {
  .staff_info__topbar {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}









































.address_list {
  margin-top: 16px;
}

.address_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  border-bottom: 1px solid #ccc;
}

.address_actions {
  display: flex;
  gap: 8px;
}

.modal_actions {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 16px;
}
