.notificationDialog {
    font-family: 'Barmeno', sans-serif;
    background-color: rgba(29, 29, 29, 0.5);
}

.notificationDialog__mainWrapper {
    padding: 24px;
    margin: 0;

    width: 100%;
    min-width: 600px;
    max-width: 600px;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;

    background-color: #fff;
    border-radius: 8px;
}

.notificationDialog__headerWrapper {
    padding: 12px;
    width: 100%;

    display: flex;
    align-items: center;
    gap: 8px;
}

.notificationDialog__headerWrapper__title {
    flex: 1;

    font-size: 24px;
    font-weight: 500;
}

.notificationDialog__headerWrapper__close_button {
    aspect-ratio: 1/1;
}

.notificationDialog__notificationWrapper {
    padding: 16px 0;
    width: 100%;
    max-height: 500px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    overflow-y: auto;
}

.notificationDialog__cta {
    padding: 0px 8px !important;
    width: fit-content;
    height: fit-content;
    text-transform: uppercase;
    color: #E11B22 !important;
}

.notificationDialog__cta > .MuiButton-label {
    height: 36px !important;
}

.emptyNotification_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}

.emptyNotification_wrapper .emptyNotification_wrapper__image {
    width: 256px;
    height: 256px;
}

.emptyNotification_wrapper .emptyNotification_wrapper__text {
    font-size: 24px;
    font-weight: 500;
    color: #000;
}