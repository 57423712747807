.staff_information__staff_list {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  
  .staff_information__pagination__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
  }
  
  .staff_information__results {
    font-size: 18px;
    font-weight: 700;
    color: #1D1D1D;
  }
  