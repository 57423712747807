.driver_role__wrapper {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Barmeno", sans-serif;
  text-align: center;
}

.driver_role__container {
  width: 100%;
  max-width: 400px;
  padding: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #fff;
}

.driver_role__title {
  margin: 0;
  font-size: 24px;
  font-weight: 600;
  color: #1D1D1D;
}

.driver_role__description {
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  color: #1D1D1D;
}

.driver_role__button_group {
  display: flex;
  align-items: center;
  gap: 16px;
}

.driver_role__separator {
  width: 100%;
  height: 0.5px;
  background-color: #e0e0e0;
  border: none;
}
