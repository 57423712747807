.MuiDialog-paperScrollPaper {
  max-height: 800px !important;
}
.description-container p{
margin: 0px;
display: inline;
}
.MuiDialog-container {
  outline: 0;
}
@media (max-height:882px) {
  #taskPopup .MuiDialog-container.MuiDialog-scrollPaper {
    outline: 0;
  }
  #detailPopup .MuiDialog-container.MuiDialog-scrollPaper{
    height: auto;
  }
}
@media (min-height:883px) {
  #taskPopup .MuiDialog-container.MuiDialog-scrollPaper {
    /* height: fit-content; */
    outline: 0;
  }

  #detailPopup .MuiDialog-container.MuiDialog-scrollPaper{
    height: fit-content;
  }
  #posdetailPopup .MuiDialog-container.MuiDialog-scrollPaper{
    height: fit-content;
  }
}
