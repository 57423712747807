.confirm_order_container .title {
    font-family: "Verona", sans-serif !important;
    font-size: 36px;
    font-weight: 400;
    line-height: 44px;
    color: #2C6F37;
    margin: 0 0 42px 0;
}

.confirm_order_container {
    container: order-section / inline-size;
}

.confirm_order_container .order_sections {
    display: flex;
    gap: 24px;
    flex-direction: column;
}

.confirm_order_container .order_sections .contact_details {
    background-color: #FFFFFF;
    padding: 24px;
    width: 100%;
    border: 1px solid #D4D4D4;
    box-shadow: 0px 2px 8px 0px #00000014;
    display: flex;
    flex-direction: column;
    gap: 40px;
    box-sizing: border-box
}

.confirm_order_container .order_sections .payment_details {
    display: flex;
    flex-direction: column;
    gap: 48px;
    flex: 1;
    width: 100%;
}

.wingoRewardsContainer{
    display: flex;
    flex-direction: column;
    gap:40px;
    padding: 24px;
    border-bottom: 1px solid #F1F1F1;
    width: 100%;
    background-color: #FFFFFF;
    box-sizing:border-box;
}

@container order-section (min-width: 1024px) {
    .confirm_order_container .order_sections {
        flex-direction: row;
    }

    .confirm_order_container .order_sections .contact_details {
        max-width: 50%;
    }
}

@container order-section (min-width: 1240px) {
    .confirm_order_container .order_sections .contact_details {
        max-width: 60%;
    }
}

@media (max-width:600px) {
    .date_time_dropdown_confirmOrder{
        flex-direction: column;
    }
    .review_cart_container{
        padding: 20px;
    }
    
}

.contact_details{
    flex: 1.7;
    width: calc(100% - 500px);
}

.personal_details, .address_details, .time_slot  {
    border: 1px solid #F1F1F1;
    border-radius: 4px;
    padding: 24px 16px;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.radio_btn p {
    font-Family: "Barmeno", sans-serif !important; 
    color: #1D1D1D !important;
    font-size: 17px !important; 
    font-weight: 500 !important; 
    line-height: 1.5 !important;
}

.address_selection{
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

.address_selection .form-control, .address_selection .form-control:focus {
  height: 56px !important;
  width: 350px !important;
  border-radius: 8px !important;
  outline: none;
  border: 1px solid #313131;
}

.address_selection .delivery_title {
    font-family: 'Barmeno', sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 19.2px;
    color: #747474;
}

.select-items {
    border-radius: 8px;
    box-shadow: 0px 2px 8px 8px #00000014;
}

.address_selection .select-selected-disabled {
    font-family: 'Barmeno', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    color: #1D1D1D;
}

.address_selection .select-selected-disabled:after {
    transform: rotate(315deg) !important;
    top: 20px !important;
}

.address_selection .select-selected:after {
    position: absolute;
    top: 16px;
    right: 24px;
    content: "";
    display: inline-block;
    width: 10px;
    height: 10px;
    border: 0px solid transparent;
    border-right: 0.2em solid black;
    border-top: 0.2em solid black;
    transform: rotate(135deg);
}

.address_selection .select-selected {
    font-family: 'Barmeno', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;   
    color: #A2A2A2; 
}

.address_selection .optionSelected {
    font-family: 'Barmeno', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    color: #747474;
    padding: 12px 16px;
}

.header {
    display: flex;
    justify-content: space-between;
}

.header img {
    width: 32px;
    height: 32px;
}

.sec-title {
    font-family: 'Barmeno', sans-serif !important;
    font-size: 24px !important;
    font-weight: 700 !important;
    line-height: 28.8px !important;
    color: #1D1D1D !important;
}

.personal_details .field {
    display: flex;
    gap: 32px;
}

.form .field {
    display: flex;
    gap: 32px;
}

.form .field .label {
    width: 150px;
    font-family: 'Barmeno', sans-serif !important;
    font-size: 18px !important;
    font-weight: 700 !important;
    line-height: 21.6px !important;
}

.form .field .value {
    font-family: 'Barmeno', sans-serif !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 20.8px !important;
}

.separator {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    width: 100%;
}

.separator__line {
    flex: 1;
    width: 100%;
    border: 1px solid #D4D4D4;
}

.separator__text {
    font-family: 'Barmeno', sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 28.8px;
    text-align: center;
    margin: 0 50px;
}

.address_title {
    font-family: 'Barmeno', sans-serif !important;
    font-size: 20px !important;
    font-weight: 700 !important;
    line-height: 24px !important;
    color: #1D1D1D;
    margin: 0 0 16px 0 !important;
}

.add_new_title {
    display: flex;
    gap: 8px;
    margin: 12px 0;
}

.add_new {
    font-family: 'Barmeno', sans-serif !important;
    font-size: 18px !important;
    font-weight: 700 !important;
    line-height: 21.6px !important;
    color: #2C6F37;
    cursor: pointer;
}

.order_sections {
   gap: 40px;
}
.payment_details {
    background-color: transparent;
}

.confirm_order_personalDetais_text_label_container{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.confirm_order_personalDetais_text_input, .confirm_order_personalDetais_text_input:focus{
    font-family: Barmeno, sans-serif;
    height: 56px;
    width: 100%;
    border-radius: 8px;
    border: 2px solid #1D1D1D;
    padding: 10 8px ;
    outline: none;
    background-color: white;
    font-weight: 500;
    font-size: 16px;
}
.confirm_order_personalDetais_label{
    font-family: "Barmeno", sans-serif;
  font-size: 16px ;
  font-weight: 400 ;
  line-height: 16px ;
  color: #747474 ;
}
.confirm_order_personalDetais_form_container{
    display: flex;
  flex-direction: column;
  gap: 16px;
}
.confirm_order_personalDetais_form_container .flag-dropdown{
    border: 2px solid #1d1d1d !important;
}
.confirm_order_personalDetais_form_action_container{
    display: flex;
    flex-direction: column;
  gap: 24px;
}
.confirm_order_personalDetais_action_container{
    display: flex;
    flex-direction: row;
    gap: 16px;
}
.confirm_order_personalDetais_button{
    width: 200px;
    height: 56px;
    box-sizing: border-box;
    padding: 16px;
    font-family: Barmeno,sans-serif ;
    font-weight: 700;
    border-radius: 8px;
}

.addAddressPopup .MuiDialog-paperWidthSm {
    max-width: 600px !important;
}

.confirm_order__add_address .MuiDialog-paperWidthSm {
    width: auto;
    min-width: fit-content;
    max-width: 1024px !important;
}

.confirm_order__add_address .main-panel {
    padding: 0 !important;
    margin: 0 !important;
}

.address_list {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.column-adress-list {
    flex-direction: column !important;
    gap: 0px;
    width: 100%;
}

.address_text .add_type {
    font-family: "Barmeno", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 19.2px;
    color: #231F20;
}

.address_text .add_address {
    font-family: "Barmeno", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    color: #231F20;
}

.MuiFormControlLabel-root.radio_btn {
    align-items: center !important;
}

.address_radio_btn {
    width: 100%;
    margin: 0px 0px 0 auto !important;
    height: 56px;
    border-bottom: 1px solid #F1F1F1;
}

.address_radio_btn .MuiFormControlLabel-label {
    width: 100%;
}

.address_edit_button {
    padding: 0 0 0 0 !important;
    width: 32px;
    height: 32px;
    background-color: #FAF4F4 !important;
}

.dlt_title {
    font-family: "Barmeno", sans-serif !important;
    font-size: 24px !important;
    font-weight: 700 !important;
    line-height: 28.8px !important;
    text-align: center;
    color: #1D1D1D;
}

.dlt_desc {
    font-family: "Barmeno", sans-serif !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    line-height: 21.6px !important;
    text-align: center;
    color: #747474;
}

.address_dlt_popup .cancelButton {
    width: 200px;
    height: 56px;
    padding: 16px 0px 0px 0px;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid #E11B22;
    background: #FFFFFF;
}

.address_dlt_popup .deleteButton {
    width: 200px;
    height: 56px;
    padding: 16px 0px 0px 0px;
    gap: 8px;
    border-radius: 8px;
    color: #FFFFFF;
    background: #E11B22;
}

.deleteButton:hover {
    background-color: #E11B22 !important;
}

.time_slot .time_title {
    font-family: "Barmeno", sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 28.8px;
    color: #1D1D1D;
}

.time_slot_radio_group {
    flex-direction: column !important;
    gap: 16px;
}

.choose_dt_time_label {
    width: 100%;
}

.immediate_ready, .choose_dt_time {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.time_for_delivery {
    padding: 12px 0px;
}

.time_for_delivery .immediate_ready .prep_title {
    font-family: "Barmeno", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 20.8px;
    color: #1D1D1D;
}

.time_for_delivery .immediate_ready .est_title {
    font-family: "Barmeno", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    color: #747474;
}

.time_for_delivery .choose_dt_time .date_time {
    font-family: "Barmeno", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 20.8px;
    color: #231F20;
}

.time_for_delivery .choose_dt_time .date-picker-container {
    max-width: 200px;
    width: 100%;
    height: 44px;
}

.time_for_delivery .choose_dt_time .date-picker-container .react-datepicker-wrapper .react-datepicker__input-container .dateCustom {
    height: 44px;
    border: 1px solid #BFBFBF;
}

.date-picker-container .MuiSvgIcon-root{
    color: #A2A2A2;
}

.time-select {
    color: #1D1D1D !important;
    font-family: "Barmeno", sans-serif !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    letter-spacing: 1px !important;
}

.time-select .MuiSelect-select {
    padding: 11.5px 32px 11.5px 12px !important;
}

.time-select fieldset {
    border-radius: 8px !important;
    border-color: #F1F1F1 !important;
}
.restaurant_selector_details{
    padding: 16px 12px 16px 40px;
    border-radius: 8px;
    box-shadow: 0px 2px 8px 0px #00000014;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: "Barmeno", sans-serif;
    height: 250px;
    min-width: 330px;
}
.restaurant_selector_name_description{
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.restaurant_selector_name{
    font-size: 24px;
    font-weight: 700
}
.restaurant_selector_description{
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.restaurant_select_action_button{
    font-weight: 700 !important;
  color: #E11B22 !important;
  border: 2px solid #E11B22 !important;
  padding: 16px !important;
  box-sizing: border-box;
  height: 56px;
  border-radius: 8px;
  display: flex;
  gap: 8px;
  max-width: 140px;
}
.restaurant_selector_container{
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 8px;
    overflow: auto;
}

.wingoRewardsContainerPoints{
    width: 150px;
    height: 30px;
    padding: 4px 8px;
    display: flex;
    flex-direction: row;
    background-color: #2C6F37;
    color: #FFFFFF;
    border-radius: 50px;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
}
.wingoRewardsContainerHeading{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    font-weight: 700;
    color: #1D1D1D;
}
.wingoRewardsAccordian{
    font-size: 20px !important;
    font-family:Barmeno, sans-serif !important;
}
.MuiPaper-root.MuiAccordion-root.accordianWingoRewards.MuiAccordion-rounded.MuiPaper-elevation1.MuiPaper-rounded::before{
background-color: transparent !important;
}
.MuiPaper-root.MuiAccordion-root.accordianWingoRewards.MuiAccordion-rounded.MuiPaper-elevation1.MuiPaper-rounded{
margin: 0px !important;
}
.divWingoRewards{
 display: flex;
  flex-direction: column;
  gap: 24px;
}
.rewardReedemption{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
.points-worth-class{
    margin-top: 4px;
  color: #2C6F37;
  font-size: 16px;
}
.payment_option_label{
    display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 500;
  font-size: 18px;
  font-family: Barmeno, sans-serif;
  gap: 16px;
  align-items: center;
}
.form_control_label_payment_option .MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
    width: 100%;
  }
.form_control_label_payment_option{
    height: 62px;
  border-bottom: 1px solid rgb(241, 241, 241);
  display: flex;
  flex-direction: row;
  gap: 0px;
}
.form_control_label_card_option{
    height: 62px;
    display: flex;
    flex-direction: row;
    gap: 0px;
    border: 1px solid #409046;
    border-radius: 8px;
    padding: 20px 16px;
    box-sizing: border-box;
}
.addNewCard{
    display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  font-weight: 700;
  color: #E11B22;
  cursor: pointer;
  border-bottom: 1px solid rgb(241, 241, 241);
  padding-bottom: 14px;
}
.couponCodeOption{
    display: flex;
  flex-direction: row;
  padding: 16px;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  font-family: barmeno, sans-serif;
  font-weight: 700;
  font-size: 20px;
  border-bottom: 1px solid #F1F1F1;
}
.couponCodeOptionDetails{
    display: flex;
  flex-direction: column;
  gap: 8px;
  min-width:350px;
}
.couponCodeOptionHeading{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.proceedPaymentDisableButton{
display: flex;
  align-items: center;
  justify-content: center;
  color: #909090 !important;
  background-color:#BFBFBF !important;
  height: 56px;
  width: 100%;
  border-radius: 8px !important;
  font-weight: 500;
}
.proceedPaymentButton{
    display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff !important;
      background-color:#E11B22 !important;
      height: 56px;
      width: 100%;
      border-radius: 8px !important;
      font-weight: 500;
    }
.error_personal_details{
    color: #E42328;
  font-family: Barmeno;
  font-size: 14px;
  font-weight: 700;
}
.cvcInputStyle {
    width: 80px;
    padding: 10px 8px;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid #BFBFBF;
    font-size: 16px;
    color: #1d1d1d;
    font-weight: 700;
    text-align: center;
    height: 32px;
    font-family: 'Barmeno', sans-serif;
}