.modal-content{
    font-family: Barmeno, sans-serif;
}
.Wingo-help-Heading{
display: flex;
flex-direction: column;
gap: 8px;
}

.helpCenterHeading{
font-size: 30px;
font-weight: 700;
color:#1D1D1D;
}
.helpCenterHeadingContent{
font-size: 20px;
font-weight: 500;
color: #747474;
}
.SupportContentSearchContainer{
    display: flex;
    flex-direction: column;
    gap: 32px;
}
.wingoHelpSearchHeadingContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.supportCardContainer{
    width: 350px;
    height: 210px;
    border-radius: 24px;
    box-shadow: 0px 4px 24px -1px #00000014;
    background: linear-gradient(118.56deg, rgba(255, 255, 255, 0.4) -18.04%, rgba(255, 255, 255, 0.1) 116.16%);
    align-items: center;
    display: flex;
    justify-content: center;
    /* background-image: url("https://s3-alpha-sig.figma.com/img/6d3c/2318/9c41a5f271485259a8da1648ccdb4d7e?Expires=1736121600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=iKwfkfdSx~wl5BGKUDJz3Vdr4EvA~HprqSYijMXoDKcVIALuiG6yoxWPX279Fow7L24jF~je76dwgpFLuSz-l5rCWP5KbV9z3OObpXn4vbfRpDkfD8u40-AAnC9pIYIPYibR-IwXsJNHIxkPhKplszaA0BXoA1clCrtYtX7VXrkh4kE36fq1-FWvSnh1wcGr~2RAtcASqc5GxlZtuQ8bhFGByZMSYtccrsG4JVe1-5IeeM08U3tjEat87rjzjhCZvnxY52SijRczARRjkDQV1H8UtTVB7k2rQsorv4WO2gKVx9LSDKuKOgJ4I8cFxKkEGOYC8cYzB6vk2D8HMR-ltw__"); */
}
.supportCardContainercontainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    grid-template-rows: auto;
    gap: 32px;
    width: 100%;
    justify-items: center
  }
  .cfsupportmodalSupport {
    background-color: #fff;
    border-radius: 8px;
    padding: 24px;
    display: flex;
  flex-direction: column;
  gap: 40px;
  }

.iconTitleContentContainer{
    display: flex;
    flex-direction: column;
    gap:24px;
    align-items: center;
    padding: 0px 19.5px;

}
.titleDescriptionContainer{
    display: flex;
  flex-direction: column;
  align-items: center;
}

.titleSupport{
    font-size: 20px;
  font-weight: 500;
  text-align: center;
}
.descriptionSupport{
    font-size: 16px;
  font-weight: 500;
  text-align: center;
color: #747474;
}

.titleQuestionSupport{
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    color: #1D1D1D;
}
.descriptionQuestionSupport{
    font-size: 20px;
    font-weight: 400;
    text-align: center;
    color: #747474;
}
.helpSearchContainer{
    display: flex;
  flex-direction: column;
  justify-content: flex-end;
}


.supportallcontentcontainer{
    display: flex;
    flex-direction: column;
    gap: 24px;
}
.supportInDepthHeading{
    display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
  font-size: 14px;
  font-weight: 500;
}
.CategoryHeadingSearchContainer{
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: center;
}
.CategoryHeadingContainer{
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.CategoryHeading{
    font-size: 24px;
    font-weight: 700;
    color: #1D1D1D;
    text-align: center;
}
.CategoryDescription{
    font-size: 18px;
    font-weight: 500;
    color: #747474;
    text-align: center;
}
.categorySearchContainer{
    max-width: 680px;
    width: 100%;
}
.MuiInputBase-root.MuiInputBase-colorPrimary{
  width: 100%;
}
.noResultFound{
    display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 24px;
  font-weight: 700;
}

.supportContactContainer{
    display: flex;
  flex-direction: row;
  background-color: #F7F4EF;
  border-radius: 16px;
  padding: 12px;
  justify-content: space-between;
}

.supportPersonInfoContainer{
    display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px #00000014;
  font-size: 16px;
  font-weight: 500;
  color: #1D1D1D;
}
.contactSupport{
    display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}