.roles_wrapper > * {
    box-sizing: border-box;
}

.roles_wrapper {
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;
}

.roles__box {
    padding-bottom: 24px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;

    font-family: "Barmeno", sans-serif;

    background-color: #fff;
    border-radius: 8px;
}

.roles__header {
    display: flex;
    flex-direction: column;
    gap: 4px;

    text-align: center;
}

.roles__banner {
    height: 326px;
    width: 326px;
    aspect-ratio: 1/1;
}

.roles__title {
    margin: 0;

    font-size: 24px;
    font-weight: 700;
    line-height: 1.25;
}

.roles__description {
    margin: 0;

    font-size: 18px;
    font-weight: 500;
    line-height: 1.5;

    color: #BFBFBF;
}

.roles__list {
    padding: 16px 0;

    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 1fr));
    gap: 16px;

    font-family: "Barmeno", sans-serif !important;
}

.roles__item {
    padding: 12px;
    min-height: 80px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;

    color: #A2A2A2;
    border: 1px solid #A2A2A2;
    border-radius: 8px;
    cursor: pointer;
}

.roles__item[data-active="true"] {
    color: #E11B23;
    fill: #E11B23;
    border-color: #E11B23;
}

.roles__item_icon {
    width: 24px;
    height: 24px;
    fill: #A2A2A2 !important;
}

.roles__item_icon[data-active="true"] {
    fill: #E11B23 !important;
}

.roles__item_title {
    margin: 0;

    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
}

.roles__item_title[data-active="true"] {
    color: #1D1D1D;
}

.roles__button {
    padding: 0px 16px !important;
    width: 100%;
    max-width: 520px;

    color: #fff !important;
    background-color: #E11B23 !important;
    border-radius: 8px !important;
}

.roles__button_label {
    height: 48px !important;
    text-transform: uppercase;
}

.roles__dialog {
    border-radius: 16px !important;
}

.roles__dialog_close {
    position: absolute !important;
    top: 8px;
    right: 8px;
}

.roles__dialog_close__icon {
    width: 24px !important;
    height: 24px !important;
    color: inherit !important;
}
