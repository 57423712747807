.dashboard__wrapper * {
    margin: 0;
    font-family: "Barmeno", sans-serif;
    box-sizing: border-box;

    --border-radius: 8px;
    --card-background: #FFFFFF;
    --card-border: 1px solid #F1F1F1;
    --card-shadow: 0px 2px 8px 0px #00000014;
}

.dashboard__wrapper {
    position: relative;
    
    height: 100vh;
    
    background-color: #F3F2F7;
}

.dashboard__sidebar {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    
    width: 200px;

    background-color: #FFFFFF;
    border-right: 1px solid #EB666B;
    border-radius: 0 24px 24px 0;
    box-shadow: 0px 4px 8px 0px #E32B2B14;
}

.dashboard__main {
    container: admin-dashboard / inline-size;

    padding: 32px 24px;
    margin-left: 200px;
    
    display: flex;
    flex-direction: column;
    gap: 24px;

    height: 100vh;
    overflow-y: auto;
    overflow-x: clip;
}

.dashboard__scroll {
    padding: 0 16px 16px 0;
    overflow-y: auto;
    overflow-x: clip;
}