
.week_view__header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  height: auto;
  text-align: center;
  border: none;
}

.week_view__header[data-error="true"] {
  color: #E11B22;
}

.week__view__popover {
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-family: "Barmeno", sans-serif;
}

.week__view__popover__wrapper {
  max-height: 500px !important;
  max-width: 500px !important;
  padding-right: 12px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: auto;
}

.week__view__popover__header {
  padding: 8px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  border-bottom: 1px solid #F1F1F1;
}

.week__view__popover__header > h1 {
  margin: 0;
  font-size: 24px;
  font-weight: 700;
}

.week__view__popover__section {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.week__view__popover__section > h2 {
  margin: 0;
  font-size: 16px;
  font-weight: 700;
}

.week__view__popover__list {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

ul.week__view__popover__list {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: #747474;
  list-style-position: inside;
  list-style-type: none;
  /* list-style-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjRTQyMzI4IiB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAyNCAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJtMjIuNyAxNy41LTguMS0xNGMtLjgtMS40LTIuNy0xLjktNC4xLTEuMS0uNS4zLS45LjctMS4xIDEuMWwtOC4xIDE0Yy0uOCAxLjQtLjMgMy4zIDEuMSA0LjEuNS4zIDEgLjQgMS41LjRIMjBjMS43IDAgMy0xLjQgMy0zIC4xLS42LS4xLTEuMS0uMy0xLjVNMTIgMThjLS42IDAtMS0uNC0xLTFzLjQtMSAxLTEgMSAuNCAxIDEtLjQgMS0xIDFtMS01YzAgLjYtLjQgMS0xIDFzLTEtLjQtMS0xVjljMC0uNi40LTEgMS0xczEgLjQgMSAxeiIvPjwvc3ZnPg==); */
}

ul.week__view__popover__list > li {
  line-height: 1.2;
}
