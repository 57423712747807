.menu__paper {
    font-family: "Barmeno", sans-serif !important;
    background-color: #FFFFFF !important;
    border-radius: 8px !important;
    border: 1px solid #D4D4D4 !important;
    box-shadow: 0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008 !important;
}

.menu__list {
    padding: 4px !important;
}

.menu__item {
    padding: 9px 20px !important;
    height: 36px !important;
}

.menu__item__text {
    font-family: "Barmeno", sans-serif !important;  
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #333333 !important;
}