.already_subscribed {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    padding: 0 40px 40px 40px;
    margin: 0 !important;
}

.already_subscribed .description_text {
    font-family: "Barmeno", sans-serif !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    line-height: 21.6px !important;
    color: rgba(116, 116, 116, 1) !important;
    margin: 16px 0 0 0 !important;
    text-align: center;
}

.already_subscribed .sucess_text {
    font-family: "Barmeno", sans-serif !important;
    font-size: 24px !important;
    font-weight: 700 !important;
    line-height: 28.8px !important;
    color: rgba(0, 0, 0, 1) !important;
    text-align: center;
    display: flex;
  justify-content: center;
}

.navigate {
    border-radius: 8px;
    background-color: rgba(225, 27, 34, 1) !important;
    color: rgba(255, 255, 255, 1) !important;
    font-family: "Barmeno", sans-serif !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 19.2px !important;
    padding: 18px 50px !important;
    height: 56px !important;
    width: 472px !important;
}