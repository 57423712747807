.email-account-login .Mui-focused fieldset {
    border: 1px solid #313131 !important;
}

.email-account-login input#outlined-basic:focus-visible {
    border: none !important;
  }

.banner_box {
    width: 670px;
}

.mobileBanner {
    border-radius: 32px;
}

.mobileBanner {
    display: none;
}

/* Media query for screens up to 744px width */
@media (max-width: 744px) {
    .desktopBanner {
        display: none;
    }
    .main-content {
        padding: 40px 24px;
    }
    .banner_box {
        width: 100%;
    }
    .wrapper {
        flex-direction: column-reverse !important;
        width: 100% !important;
        margin: 40px 0px 0px 0px !important;
    }

    .wrapper .sub_wrapper {
        padding: 40px 24px;
    }

    .wrapper .sub_wrapper .inputField {
        width: 100% !important;
    }

    #btnEmailLogIn {
        width: 100% !important;
    }
    .mobileBanner {
        display: block;
    }
}

/* Media query for screens from 745px to 1279px width */
@media (min-width: 745px) and (max-width: 1279px) {
    .desktopBanner {
        display: none;
    }
    .main-content {
        padding: 40px;
    }
    .banner_box {
        width: 100%;
    }
    .wrapper {
        flex-direction: column-reverse !important;
        width: 100% !important;
        margin: 0px 0px 0px 0px !important;
    }
    .wrapper .sub_wrapper {
        padding: 24px 80px 52px;
    }
    .mobileBanner {
        display: block;
    }
}

/* Media query for screens from 1280px to 1919px width */
@media (min-width: 1280px) and (max-width: 1919px) {
    .mobileBanner {
        display: none;
    }

    .banner_box {
        width: 670px;
    }
}

.singInButtonLogin{
    margin-top: 34px !important;
}

#signIn_password_fieild{
    padding: 12.5px 14px !important;
}