.emptyNotificationWrapper {
    margin: 24px 0 0 0;
    padding: 48px 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;

    background-color: #FAF4F4;
    border-radius: 16px;
}

.emptyNotificationWrapper__image {
    width: 327px;
    height: 327px;
    aspect-ratio: 1/1;
}

.emptyNotificationWrapper__title {
    margin: 0;
    font-size: 20px;
    font-weight: 700;
}

.emptyNotificationWrapper__description {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
}