.dashboard__grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 24px;

    height: 100vh;
}

.dashboard_grid__item {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.dashboard_grid__item.content {
    grid-column: 1 / span 9;
}

.dashboard_grid__item.analytics {
    grid-column: 10 / span 12;
}

@container admin-dashboard (width < 1280px) {
    .dashboard__grid {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    .dashboard_grid__item.analytics {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 24px;
    }
}

.dashboard_item__orders {
    padding: 16px;
    width: 100%;
    height: fit-content;

    display: flex;
    flex-direction: column;
    gap: 16px;

    background-color: var(--card-background);
    border: var(--card-border);
    box-shadow: var(--card-shadow);
    border-radius: var(--border-radius);
}

.dashboard__datagrid {
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 16px;

    border: var(--card-border);
    box-shadow: var(--card-shadow);
    border-radius: var(--border-radius);
    overflow: clip;
}

.dashboard_item__orders__title {
    font-size: 24px;
    font-weight: 700;
}

.dashboard_item__cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(196px, 1fr));
    grid-gap: 16px;
}

.dashboard_item__card:nth-child(1) {
    background-color: #FEF3C7;
}

.dashboard_item__card:nth-child(2) {
    background-color: #FEE2E2;
}

.dashboard_item__card:nth-child(3) {
    background-color: #D1FAE5;
}

.dashboard_item__card {
    padding: 16px;

    display: flex;
    flex-direction: column;
    gap: 8px;

    border-radius: var(--border-radius);
}

.card__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 8px;
}

.card__title {
    flex: 1;

    font-size: 18px;
    font-weight: 500;
    color: #747474;
}

.card__icon_button {
    aspect-ratio: 1;
    width: 48px;

    background-color: #fff !important;
    color: #000 !important;
    border-radius: 8px !important;
}

.card__label {
    color: #1d1d1d;
    font-size: 30px;
    font-weight: 700;
}

.card__footer {
    color: #747474;
    font-size: 14px;
    font-weight: 500;
}

.analytics__card {
    width: 100%;
    height: fit-content;
    padding: 16px;

    display: flex;
    flex-direction: column;
    gap: 24px;

    background-color: var(--card-background);
    border: var(--card-border);
    border-radius: var(--border-radius);
    box-shadow: var(--card-shadow);
}

.analytics__card__footer {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.analytics__footer__items {
    display: flex;
    align-items: center;
    gap: 16px;
}

.card_footer__icon {
    aspect-ratio: 1;
    width: 24px;
}

.analytics__card__items {
    display: flex;
    align-items: center;
    column-gap: 8px;
}

.footer__label {
    font-size: 32px;
    font-weight: 700;
}

.footer__subtext {
    color: #2C6F37;
    font-size: 14px;
    font-weight: 700;
}

.footer__caption {
    color: #747474;
    font-size: 12px;
    font-weight: 500;
}

.analytics__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
}

.analytics_header__total_revenue {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.analytics_header__select {
    max-width: 128px;
    border-radius: 8px !important;
    font-family: inherit !important;
}

.analytics_header__select__input {
    padding: 12px !important;
    padding-right: 32px !important;
    border-color: #2C6F37 !important;
}

.analytics_header__select__menu {
    margin-top: 8px;
    border-radius: 8px !important;
    font-family: "Barmeno", sans-serif !important;
}

.analytics_header__select__item {
    font-family: "Barmeno", sans-serif !important;
}

.analytics_header__select__item:hover {
    background-color: rgba(44, 111, 55, 0.5) !important;
}

.analytics_header__select__item.selected {
    background-color: #2C6F37 !important;
    color: #fff !important;
}

.header__label {
    color: #747474;
    font-size: 18px;
    font-weight: 500;
}

.header__value {
    color: #1d1d1d;
    font-size: 18px;
    font-weight: 500;
}

.receipt__print {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
}

.receipt__print__button {
    width: fit-content;
}

.search__wrapper {
    padding: 8px 12px;
    min-width: 256px;

    display: flex;
    align-items: center;
    column-gap: 8px;

    background-color: #fff;
    font-family: "Barmeno", sans-serif;
    color: #DADADA;
    border-radius: 8px;

    border: 1px solid #BFBFBF;
    cursor: text;
}

.autocomplete__wrapper {
    padding: 8px !important;
    min-width: 256px !important;
    background-color: #fff;
    border: 1px solid #BFBFBF !important;
    border-radius: 8px;
}

.autocomplete__wrapper__input__root {
    padding: 0 !important;
}

.autocomplete__wrapper__input {
    padding: 0 !important;
    font-family: "Barmeno", sans-serif !important;
}

.autocomplete__wrapper__input__outline {
    border: none !important;
}

.autocomplete__popper {
    margin-top: 12px !important;
    width: 256px !important;
    border-radius: 8px !important;
}

.autocomplete__menu__group {
    padding: 8px 16px !important;
    font-size: 14px !important;
    font-family: "Barmeno", sans-serif !important;
    font-weight: 700 !important;
    color: #BFBFBF !important;
}

.autocomplete__menu__item {
    padding-right: 16px !important;
    font-size: 14px !important;
    font-family: "Barmeno", sans-serif !important;
    text-overflow: ellipsis;
    overflow: clip;
}

.select_date__menu {
    margin-top: 8px;
    border-radius: 8px !important;
}

.select_date__list {
    padding: 0 !important;
}

.select_date__input {
    min-width: fit-content !important;
}

.select_date__item {
    font-family: "Barmeno", sans-serif !important;
}

.select_date__item:hover {
    background-color: rgba(44, 111, 55, 0.5) !important;
}

.select_date__item.selected {
    background-color: rgba(44, 111, 55, 0.5) !important;
}

.dashboard__banner {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: #ffd4d4;
    border-radius: var(--border-radius);
    box-shadow: var(--card-shadow);
}

.dashboard__banner > h2 {
    margin: 0;
    font-size: 24px;
    font-weight: 700;
}

ul.dashboard__notes {
    list-style-position: outside;
    list-style-type: disc;
    padding-inline: 24px 16px;
    max-height: 116px;
    overflow-y: auto;
}

li.dashboard__note {
    line-height: 1.5;
}
