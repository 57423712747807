.calendar__wrapper {
  width: 100%;
  height: 100%;

  background-color: rgba(255, 255, 200, 0.8);
}

.calendar__view {
  border: none;
}

.rbc-time-view, .rbc-month-view {
  border: none;
}

.rbc-header + .rbc-header {
  border-left: none;
}

.rbc-time-view .rbc-row {
  box-sizing: border-box;
  min-height: auto;
}

.rbc-button-link {
  color: inherit;
  background: none;
  margin: 0;
  padding: 0;
  border: none;
  cursor: pointer;
  -webkit-user-select: text;
  -moz-user-select: text;
  user-select: text;
  height: 44px;
}

.rbc-time-column .rbc-timeslot-group {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rbc-time-content {
  display: flex;
  flex: 1 0;
  align-items: flex-start;
  width: 100%;
  border-top: none;
  border-bottom: none;
  overflow-y: auto;
  position: relative;
}

.rbc-month-row {
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  height: 100%;
  min-height: 196px;
}

.rbc-time-column .rbc-timeslot-group {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 48px;
}

.rbc-timeslot-group:first-child {
  border-top: 1px solid #ddd;
}

.rbc-event {
  padding: 0 !important;
  height: 100%;
  width: 100%;
  min-height: 24px;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  gap: 8px;
  background-color: transparent;
  color: #1D1D1D;
  border: none !important;
  /* border-left: 4px solid #F59E0B !important; */
  border-radius: 8px;
  box-shadow: 0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008, 0px 25px 50px 0px #00000017;
  z-index: 2;
}

.rbc-event.rbc-selected {
  background-color: transparent;
}

.rbc-event-content {
  width: 100%;
  flex: none !important;
  word-wrap: break-word;
  line-height: 1;
  min-height: 1em;
}

.rbc-event-label {
  font-size: 80%;
  text-wrap: pretty;
}

.rbc-addons-dnd-resizable {
  padding: 16px 0;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.rbc-events-container {
  margin: 0 4px !important;
}

.rbc-event-label {
    display: none;
}

.rbc-off-range-bg {
  background-color: #ffffff !important;
}

.rbc-time-column .rbc-timeslot-group {
  padding: 0 8px;
  min-height: 64px !important;
}

.rbc-time-content > * + * > * {
  border-left: none;
}

.rbc-time-header-content {
  border-left: none;
}

.rbc-header {
  border-bottom: none;
}

.rbc-allday-cell {
  display: none;
}
