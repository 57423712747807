.account-regirstration .Mui-focused fieldset {
    border: 1px solid #313131 !important;
}

.account-regirstration .password.Mui-focused fieldset {
    border: 1px solid #313131 !important;
}

.account-regirstration .password input {
  padding: 12.5px 14px !important;
}
.account-regirstration input#outlined-basic:focus-visible {
    border: none !important;
  }

  .error.react-tel-input input {
    border-color: rgba(248, 113, 113, 1) !important;
  }

  .error.react-tel-input .flag-dropdown {
    border-color: rgba(248, 113, 113, 1) !important;
  }
  .signin_mobile .flag-dropdown{
    border: 1px solid rgb(144, 144, 144) !important;
}