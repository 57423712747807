.MuiDrawer-root.MuiDrawer-modal {
    position: relative !important;
}

.item_wrapper {
    width: 265px !important;
    height: 32px;
    border-bottom: 1px solid #D4D4D4 !important;
    border-radius: 8px !important;
    margin: 24px auto !important;
    padding: 8px 16px 8px 0px !important;
}
.mob-view span{
    font-family: Barmeno, sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    margin: 8px 12px;
    color:#E11B22 !important;
}

.item_wrapper svg {
    color: #313131;
    width: 16px;
    height: 16px;
  }

.item_text span {
    font-family: Barmeno, sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: #313131;
    margin: 8px 12px;
}

.sub_menu_list {
    width: 265px;
    margin: 0 auto !important;
    border: 1px solid #F5F5F5;
    border-radius: 8px;
}

.mg_btm {
    margin-bottom: 8px !important;
}

.mg_btm .item_text span {
    font-weight: 700;
    line-height: 19.2px;
    color:  #E11B22;
}

.sub_menu_item_wrapper {
    margin: auto !important;
    padding: 0px !important;
    width: 265px !important;
    height: 38px !important;
}

.sub_menu_item_text {
    margin: 0px !important;
}

.sub_menu_item_text span {
    padding: 12px 16px !important;
    font-family: Barmeno, sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: #313131;
}

.MuiList-padding {
    padding: 16px 0 !important;
}