.timeline__wrapper {
  padding: 24px 0;
  overflow-x: auto;
  height: 100%;
}

.timeline__header__title {
  font-weight: bold;
}

.timeline__header__days {
  padding: 8px 0;
  grid-column: 2 / 9;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  align-items: center;
  column-gap: 1rem;
  row-gap: 4px;
  font-weight: bold;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

.timeline__row {
  display: grid;
  grid-template-columns: repeat(8, minmax(200px, 1fr));
  align-items: center;
}

.timeline__members {
  grid-column: 1 / 1;
  padding: 8px 0;
  height: 100%;
  border-bottom: 1px solid #ddd;
}

.timeline__body {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.timeline__member {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.timeline__events {
  padding: 4px;
  grid-column: 2 / 9;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  align-items: center;
  grid-gap: 4px;
  border-bottom: 1px solid #ddd;
}

.timeline__event {
  padding: 8px;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 8px;
  background-color: #FEF6E7;
  color: #1D1D1D;
  text-align: center;
  border: none !important;
  border-left: 4px solid #F59E0B !important;
  border-radius: 8px;
  box-shadow: 0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008, 0px 25px 50px 0px #00000017;
}
