.notification_settings_wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    font-family: "Barmeno", sans-serif;
}

.notification_settings_wrapper__title {
    font-size: 20px;
    font-weight: 700;
}

.notification_settings_wrapper__form {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.notification_settings_wrapper__form__label {
    padding: 8px 0;
    justify-content: space-between;
    border-bottom: 2px solid #F1F1F1;
}

.notification_settings_wrapper__form__label_text {
    font-family: "Barmeno", sans-serif !important;
    font-size: 16px;
    font-weight: 500;
}